<template>
  <div class="ta-lot-bidding d-flex flex-column">
    <b-modal
      id="max-bid-modal"
      ref="maxBidModal"
      :title="$t('Set your desired max bid amount')"
      v-model="showModal"
      static
      lazy
    >
      <form ref="maxBidForm" @submit.stop.prevent>
        <b-form-group
          label-for="max-bid-amount"
          :invalid-feedback="$t('Invalid Max Bid Amount')"
          :state="maxBidState"
        >
          <b-input-group :prepend="lot.currency.symbol">
            <b-form-input id="max-bid-amount"
                          type="number"
                          :state="maxBidState"
                          v-model="maxBidAmount"
                          :placeholder="$t('Your Max Bid')"
                          required
            />
          </b-input-group>
        </b-form-group>
      </form>

      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-button
            variant="primary"
            size="sm"
            class="mx-2"
            @click="saveMaxBidAmount"
          >
            {{ $t('Save') }}
          </b-button>
          <b-button variant="secondary" size="sm" @click="showModal=false">
            {{ $t('Close') }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <div class="ta-lot-bidding-timer d-flex align-items-center justify-content-between">
      <div>
        <lot-countdown-timer :lot="lot"/>
      </div>
      <watchlist-button v-if="mainConfig.enableWatchlist" :lot="lot"/>
    </div>
    <div class="ta-lot-bidding-content flex-grow-1">

      <lot-collection-information :lot="lot"/>

      <!-- Notification to show for latest bid or outbid -->
      <div v-if="mainConfig.enableBidding && showLatestBidNotification" class="mb-3">
        <hr>
        <LatestBidNotification :is-lot-sold="isLotSold"
                               :is-lot-unsold="isLotUnsold"
                               :i-won-the-item="iWonTheItem"
                               :lot="lot"
                               :is-current-user-last-bidder="isCurrentUserLastBidder"
                               @show-max-bid="showMaxBidModal"/>
      </div>

      <!--/ Notification to show for latest bid or outbid -->

      <div>
        <hr>
        <!--      Start Price-->
        <div class="ta-lot-start-price d-flex justify-content-between align-items-center">
          {{ $t('Start Price') }}
          <span class="line-height-sm">
            {{ lot.start_price | asCurrency(lot.currency.code) }}
          </span>
        </div>
        <!--      /Start Price-->

        <!--      Start Price-->
        <div class="ta-lot-start-price d-flex justify-content-between align-items-center">
          {{ $t('Estimate') }}
          <span class="line-height-sm">
            {{ lot.low | asCurrency(lot.currency.code) }} - {{ lot.high | asCurrency(lot.currency.code) }}
          </span>
        </div>
        <!--      /Start Price-->
      </div>

      <!-- Current bid, input and buy now -->
      <div v-if="mainConfig.enableBidding && !(isLotSold || isLotUnsold)" class="ta-lot-bid-input mt-2">
        <!-- Using components -->

        <!-- Current Bid -->
        <div v-if="lot.winning_bid" class="d-flex align-items-end justify-content-between">
          <span>{{ $t('Current Bid') }}</span>
          <span class="text-primary text-large font-weight-bold">
            {{ lot.winning_bid.amount | asCurrency(lot.currency.code) }}
          </span>
        </div>
        <!--/ Current Bid -->

        <!-- Bidding input with max bid link -->
        <div>
          <b-input-group :prepend="lot.currency.symbol">
            <b-form-input v-model="newBidAmount" :placeholder="$t('Your Bid')"></b-form-input>
            <b-input-group-append id="place-bid-button-wrapper" v-b-tooltip.hover>
              <b-button id="place-bid-button"
                        @click="onBidButtonClick($event)"
                        :disabled="biddingDisabled"
                        variant="primary"
                        class="ta-lot-bid-btn d-flex align-items-center">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gavel"
                     class="svg-inline--fa fa-gavel fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                  <path fill="currentColor"
                        d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"></path>
                </svg>
                {{ $t('Place a Bid') }}
              </b-button>
              <b-tooltip v-if="biddingDisabled" container="artisioTimedAuctions" target="place-bid-button-wrapper">
                <template v-if="!is_authorized">
                  {{ $t('Please login to make a bid') }}
                </template>
                <template v-else-if="!biddingStarted">
                  {{ $t('Lot is not open yet') }}
                </template>
                <template v-else-if="biddingFinished">
                  {{ $t('Lot has ended') }}
                </template>
                <template v-else-if="isCurrentUserLastBidder">
                  {{ $t('You are the latest bidder') }}
                </template>
              </b-tooltip>
            </b-input-group-append>
          </b-input-group>
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <a href="javascript:void(0)" id="change-max-bid-button"
                 v-b-tooltip:hover
                 @click="showMaxBidModal" class="btn-link text-sm">
                {{ lot.max_bid ? $t('Change your max bid') : $t('Set your max bid') }}
              </a>
              <small class="ml-1"> - {{ lot.max_bid | asCurrency(lot.currency.code) }}</small>
            </div>
            <a href="javascript:void(0)">
              <small>{{ lot.num_of_bids || 0 }} {{ $t('bids') }}</small>
            </a>
            <b-tooltip v-if="biddingFinished" target="change-max-bid-button" container="artisioTimedAuctions">
              {{ $t('Lot has ended') }}
            </b-tooltip>
          </div>
        </div>
        <!--/ Bidding input with max bid link -->

        <!--        Buy Now button-->
        <buy-now-button :lot="lot" :disabled="buyNowDisabled" @confirm="place_bid" :bidding-started="biddingStarted"
                        :bidding-finished="biddingFinished"/>
      </div>
      <!--/ Current bid, input and buy now -->

      <!--        Signup and login-->
      <login-signup-buttons v-if="mainConfig.enableAuth && !is_authorized"/>
      <!--        /Signup and login-->

      <hr>

      <toast-notification/>

      <div v-if="mainConfig.enablePrivateTreaty">
        <template v-if="is_authorized">
          <make-an-offer-button :lot="lot" class="mb-3"/>
          <make-an-enquiry-button :lot="lot" />
          <hr>
        </template>
        <p v-else class="text-muted text-center py-2">
          {{ $t('Please {link} to make an offer') }}
        </p>
      </div>

      <LatestBids :lot="lot"/>

      <div v-if="mainConfig.descriptionInSidebar">
        <LotViewDescription :lot="lot"/>
      </div>

      <div v-if="mainConfig.sharesInSidebar">
        <hr>
        <SocialShareIcons :lot="lot"/>
      </div>

      <!-- Additional links given from config -->
      <div class="mt-4">
        <a v-for="link in mainConfig.links"
           @click="linkHandler({event: $event, link})"
           :href="link.url"
           :key="link.url"
           :target="link.target || '_self'"
           class="btn-link d-flex justify-content-between align-items-center  btn-block mb-3">
          {{ link.text }}
          <svg style="width: 20px;" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
          </svg>
        </a>
      </div>
      <!--/ Additional links given from config -->
    </div>

    <!-- Place a bid -->
    <BidConfirmation v-if="!doNotShowConfirmOnBid[lot.uuid]"
                     :show.sync="showBidConfirmation"
                     target="place-bid-button"
                     :lot="lot"
                     @confirm="bidConfirmed($event)"/>
    <!--/ Place a bid -->
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import moment from 'moment';
import CountDownTimer from "../../CountDownTimer";
import LatestBids from "../../lot-view/LatestBids";
import BidConfirmation from "../../lot-view/BidConfirmation";
import BuyNowConfirmation from "../../lot-view/BuyNowConfirmation";
import LatestBidNotification from "../../lot-view/LatestBidNotification";
import SocialShareIcons from "../../lot-view/SocialShareIcons.vue";
import LoginSignupButtons from "../../lot-view/sidebar/LoginSignupButtons";
import WatchlistButton from "../../lot-view/sidebar/WatchlistButton";
import BuyNowButton from "../../lot-view/sidebar/BuyNowButton";
import ToastNotification from "../../core/ToastNotification";
import LotViewDescription from "./LotViewDescription";
import MakeAnOfferButton from "./MakeAnOfferButton";

export default {
  name: "LotViewBidding",

  data() {
    return {
      showModal: false,
      newBidAmount: null,
      maxBidState: null,
      maxBidAmount: null,

      interval: null,
      biddingStarted: false,
      biddingFinished: false,
      disableBid: false,
      showBidConfirmation: false,
    }
  },

  components: {
    MakeAnOfferButton,
    LotViewDescription,
    ToastNotification,
    BuyNowButton,
    WatchlistButton,
    LoginSignupButtons,
    LatestBidNotification,
    BuyNowConfirmation,
    BidConfirmation,
    LatestBids,
    CountDownTimer,
    SocialShareIcons
  },

  computed: {
    ...mapGetters(['currentLocale', 'customerMeta', 'is_authorized']),
    ...mapState(['timeDiff', 'signal', 'doNotShowConfirmOnBid', 'mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data
    }),
    currentBid() {
      return this.lot.winning_bid ? this.lot.winning_bid.amount : 0;
    },

    isCurrentUserLastBidder() {
      if (this.customerMeta?.uuid && this.lot.winning_bid?.customer_uuid) {
        return this.customerMeta.uuid === this.lot.winning_bid.customer_uuid;
      }
      return false;
    },

    next() {
      const key = this.currentBid.toFixed(1);

      if (!this.lot.increments)
        return key;

      return this.lot.increments[key] ?? (this.currentBid + parseFloat(this.lot.increments['after_bid'])).toFixed(1);
    },

    biddingDisabled() {
      return !this.is_authorized || this.disableBid || this.isCurrentUserLastBidder || !this.biddingStarted || this.biddingFinished
    },
    buyNowDisabled() {
      return !this.is_authorized ||
        !this.biddingStarted ||
        this.biddingFinished ||
        (this.lot.winning_bid && this.lot.buy_now_price && this.lot.winning_bid.amount >= this.lot.buy_now_price)
    },
    isLotSold() {
      return this.biddingFinished && !!this.lot.winning_bid
    },
    isLotUnsold() {
      return this.biddingFinished && !this.lot.winning_bid
    },
    iWonTheItem() {
      return this.isCurrentUserLastBidder && this.biddingFinished
    },
    lotStartDate() {
      return this.lot.actual_start_date;
    },
    lotEndDate() {
      return this.lot.actual_end_date;
    },
    showLatestBidNotification() {
      return this.isLotUnsold || this.isLotSold || this.lot.has_my_bid
    }
  },

  watch: {
    next(newVal) {
      this.newBidAmount = newVal;
    },

    signal(_) {
      this.disableBid = false;
    }
  },

  methods: {
    ...mapActions(['toggleWatchlist', 'createMaxBid', 'sendBidInSocket', 'show401Notification', 'loginHandler', 'signupHandler', 'linkHandler']),
    ...mapMutations(['markDoNotShowConfirmOnBid']),

    showMaxBidModal() {
      if (this.biddingFinished) {
        return;
      }
      if (this.is_authorized) {
        this.maxBidAmount = this.lot.max_bid;
        this.showModal = true;
      } else {
        this.show401Notification();
      }
    },

    saveMaxBidAmount() {
      let valid = this.$refs.maxBidForm.checkValidity() && (Number(this.maxBidAmount) > Number(this.lot.max_bid));
      this.maxBidState = valid;

      if (valid) {
        this.createMaxBid({
          lot_uuid: this.lot.uuid,
          amount: this.maxBidAmount
        });
        this.$refs.maxBidModal.hide();
      }
    },

    bidConfirmed(dontAsk) {
      if (dontAsk) {
        console.log("Don't ask ", dontAsk);
        this.markDoNotShowConfirmOnBid(this.lot.uuid);
      }
      this.place_bid();
    },

    onBidButtonClick() {
      if (this.doNotShowConfirmOnBid[this.lot.uuid]) {
        this.place_bid()
      } else {
        this.showBidConfirmation = !this.showBidConfirmation;
      }
    },

    place_bid(buy_now = false) {
      if (!this.is_authorized) {
        this.show401Notification();
        return;
      }
      this.disableBid = true;

      if (buy_now) {
        this.showBuyNowConfirmation = false;
      }

      const payload = buy_now ? {
        'action': 'bid',
        'data': {
          'lot_uuid': this.lot.uuid,
          'amount': this.lot.buy_now_price,
          'buy_now': 1
        }
      } : {
        'action': 'bid',
        'data': {
          'lot_uuid': this.lot.uuid,
          'amount': this.newBidAmount,
        },
      }

      this.sendBidInSocket(payload)
    },
    updateBiddingFlags() {
      const currentTime = Date.now();

      this.biddingStarted = this.lotStartDate < currentTime;
      this.biddingFinished = this.lotEndDate < currentTime;
      // console.log(this.lotStartDate.toString(), this.lotEndDate.toString());
      if (this.lot.winning_bid && this.lot.winning_bid.buy_now) {
        this.biddingFinished = true;
      }
    }
  },
  mounted() {
    this.newBidAmount = this.next;
    this.updateBiddingFlags();
    if (!this.biddingStarted || !this.biddingFinished) {
      this.interval = setInterval(() => {
        this.updateBiddingFlags();
        if (this.biddingFinished) {
          clearInterval(this.interval);
        }
      }, 1000);
    }
  },

  destroyed() {
    clearInterval(this.interval);
  }
}
</script>

<style lang="scss">
#artisioTimedAuctions {
  .ta-lot-bidding {
    height: 100%;

    hr {
      border-top: 3px solid white;

    }
  }

  .ta-btn-watchlist {
    position: static;
  }
}

</style>

<style scoped lang="scss">
@import "../../../bootstrap/import";


.ta-lot-bidding-content {
  position: relative;
  background-color: $gray-100;
  padding: 1rem;
}

.ta-lot-bidding-timer {
  height: 40px;
}

.ta-lot-bid-btn {
  svg {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-right: 0.5rem;
    width: 16px;
  }
}

.ta-lot-bid-input {
  position: relative;
}
</style>
