import Vue from 'vue'
import VueRouter from 'vue-router'
import Auctions from "../views/Auctions";
import Invoices from "../views/Invoices";
import Settlements from "../views/Settlements";
import Items from "../views/Items";
import TemporaryItems from "../views/TemporaryItems";
import AuctionView from "../views/AuctionView";
import Lots from "../views/Lots";
import LotView from "../views/LotView";
import Login from "../views/auth/Login";
import Signup from "../views/auth/Signup";
import ForgotPassword from "../views/auth/ForgotPassword";
import ResetPassword from "../views/auth/ResetPassword";
import AuthLayout from "../components/layouts/AuthLayout";
import DefaultLayout from "../components/layouts/DefaultLayout";
import NotFound from "../components/NotFound";
import CONFIG from "../user-config";
import store from '../store';
import VerifyEmail from "../views/auth/VerifyEmail";
import Home from "../views/Home";
import Profile from "../views/Profile";
import Signout from "../views/auth/Signout";
console.log(CONFIG);
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: CONFIG.defaultPage
  },
  {
    path: '/app',
    name: 'App',
    redirect: CONFIG.defaultPage,
    component: DefaultLayout,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/private-treaty/auctions',
        name: 'PrivateTreaty',
        component: Auctions
      },
      {
        path: '/private-treaty/auctions/:uuid',
        name: 'PrivateTreatyView',
        component: AuctionView
      },
      {
        path: '/private-treaty/lots',
        name: 'PrivateTreatyLots',
        component: Lots
      },
      {
        path: '/private-treaty/lots/:lotno',
        name: 'PrivateTreatyLotView',
        component: LotView
      },
      {
        path: '/auctions',
        name: 'AuctionsTimed',
        component: Auctions
      },
      {
        path: '/auctions/catalog',
        name: 'AuctionsCatalog',
        component: Auctions
      },
      {
        path: '/auctions/:uuid',
        name: 'AuctionViewTimed',
        component: AuctionView
      },
      {
        path: '/auctions/catalog/:uuid',
        name: 'AuctionViewCatalog',
        component: AuctionView
      },
      {
        path: '/lots/',
        name: 'Lots',
        component: Lots
      },
      {
        path: '/lots/:lotno',
        name: 'LotView',
        component: LotView
      },
      {
        path: '/invoices',
        name: 'invoices',
        meta: {requiresAuth: true},
        component: Invoices
      },
      {
        path: '/settlements',
        name: 'settlements',
        meta: {requiresAuth: true},
        component: Settlements
      },
      {
        path: '/items',
        name: 'items',
        meta: {requiresAuth: true},
        component: Items
      },
      {
        path: '/temporary-items',
        name: 'temporary-items',
        meta: {requiresAuth: true},
        component: TemporaryItems
      },
      {
        path: '/profile',
        name: 'profile',
        meta: {requiresAuth: true},
        component: Profile
      },
      {
        path: '/sign-out',
        name: 'SignOut',
        meta: {requiresAuth: true},
        component: Signout
      },
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    redirect: '/login',
    component: AuthLayout,
    meta: {forGuestOnly: true},
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: {forGuestOnly: true},
        component: Login
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        meta: {forGuestOnly: true},
        component: ForgotPassword
      },
      {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        meta: {forGuestOnly: true},
        component: ResetPassword
      },
      {
        path: '/verify-email/:token',
        name: 'VerifyEmail',
        meta: {forGuestOnly: true},
        component: VerifyEmail
      },
    ]
  },
  {
    path: '/signup',
    name: 'Signup',
    meta: {forGuestOnly: true},
    component: Signup
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/:catchAll(.*)',
    redirect:'404'
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const cookie = Vue.prototype.$cookie.get(CONFIG.artisioCookieName)

  if (to.meta.requiresAuth && !cookie) {
    next({name: 'Login'})
  } else if (to.meta.forGuestOnly && cookie) {
    next({path: CONFIG.defaultPage})
  } else {
    next()
  }
})

export default router
