<template>
  <div class="ta-lot-start-price d-flex justify-content-between align-items-center mt-2">
    {{ $t('Start Price') }}
    <span class="line-height-sm">
      {{ lot.start_price | asCurrency(lot.currency.code) }}
    </span>
  </div>
</template>

<script>
export default {
  name: "LotStartPrice",
  props: {
    lot: {
      required: true,
      type: Object
    },
  },
}
</script>

<style scoped>

</style>
