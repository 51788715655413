<template>
  <div>
    <div v-if="auction.start_date && auction.end_date" class="mb-3">
      <auction-dates :auction="auction" />
    </div>

    <auction-description :auction="auction" />

    <auction-viewing-dates :auction="auction" />

    <auction-catalog-link :auction="auction" />
  </div>
</template>

<script>
import AuctionCatalogLink from './AuctionCatalogLink.vue';
import AuctionDates from "./AuctionDates.vue";
import AuctionDescription from "./AuctionDescription.vue";
import AuctionViewingDates from './AuctionViewingDates.vue';
export default {
  components: { AuctionDescription, AuctionDates, AuctionCatalogLink, AuctionViewingDates },
  name: "AuctionDetails",
  props: {
    auction: Object,
  },
};
</script>

<style></style>
