<template>
  <div class="ta-auth-card ta-request-password-form">
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <div class="text-center">
          <h3 class="mb-3">
            {{ $t("Forgot Your Password?") }}
          </h3>
        </div>

        <FormAlert :success-message="successMessage" :error-message="errorMessage"></FormAlert>

        <input-widget
          v-model="model.email"
          :error="errors.email"
          id="input-email"
          type="email"
          :label="$t('Email Address')"
          rules="required|email"
        />

        <div class="mb-3 d-flex justify-content-between">
          <router-link :to="{ name: 'Login' }">
            {{ $t("Back to Login") }}
          </router-link>
          <router-link :to="{ name: 'Signup' }">
            {{ $t("Signup Now") }}
          </router-link>
        </div>

        <LoadingButton block :loading="loading" :disabled="invalid">
          {{ $t("Request New Password") }}
        </LoadingButton>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LoadingButton from "../../components/core/LoadingButton.vue";
import InputWidget from "../../components/core/InputWidget.vue";
import FormAlert from "../../components/core/FormAlert";
export default {
  name: "ForgotPassword",
  data() {
    return {
      loading: false,
      successMessage: "",
      errorMessage: "",
      model: {
        email: "",
      },
      errors: {
        email: null,
      },
    };
  },
  methods: {
    ...mapActions(["requestPasswordReset"]),
    onSubmit() {
      this.loading = true;
      this.requestPasswordReset(this.model.email)
        .then((msg) => {
          this.loading = false;
          this.successMessage = msg;
          this.errorMessage = "";
        })
        .catch((msg) => {
          this.loading = false;
          this.successMessage = "";
          this.errorMessage = msg;
        });
    },
  },
  components: {FormAlert, LoadingButton, InputWidget },
};
</script>

<style lang="scss" scoped></style>
