<template>
  <div class="ta-auction-filters mr-5">
    <div class="d-flex justify-content-between">
      <h3>{{ $t('Filters') }}</h3>
      <b-button variant="outline-danger" @click="clearFilters">{{ $t('Clear Filters') }}</b-button>
    </div>
    <div class="ta-auction-department-filter my-4">
      <input-list-group :title="$t('Departments')"
                        :options="prepareOptions(auctionFilterDetails.departments)"
                        v-model="filters.department"
                        @change="filtersChanged"></input-list-group>
    </div>

    <div class="ta-auction-category-filter my-4">
      <input-list-group :title="$t('Categories')"
                        :options="prepareOptions(auctionFilterDetails.categories)"
                        v-model="filters.category"
                        @change="filtersChanged"></input-list-group>
    </div>

    <div class="ta-auction-status-filter my-4">
      <b-form-group :label="$t('Auction Status') + ':'">
        <b-form-radio v-model="filters.status" :value="$t('current')" @input="filtersChanged">{{
            $t('Current')
          }}
        </b-form-radio>
        <b-form-radio v-model="filters.status" :value="$t('past')" @input="filtersChanged">{{
            $t('Past')
          }}
        </b-form-radio>
      </b-form-group>
    </div>

    <div class="ta-auction-start-date-filter my-4">
      <h6>{{ $t('Auction Start Date') }}:</h6>
      <date-range-picker
          opens="right"
          style="width: 100%"
          ref="picker"
          :locale-data="{ firstDay: 1, format: 'mm/dd/yyyy' }"
          v-model="startDateRange"
          @update="filtersChanged()"
      >
        <template v-slot:input="picker">
          {{ picker.startDate | date }} - {{ picker.endDate | date }}
        </template>
      </date-range-picker>
    </div>

    <div class="ta-auction-start-date-filter my-4">
      <h6>{{ $t('Auction End Date') }}:</h6>
      <date-range-picker
          opens="right"
          style="width: 100%"
          ref="picker"
          v-model="endDateRange"
          @update="filtersChanged()"
      >
        <template v-slot:input="picker">
          {{ picker.endDate | date }} - {{ picker.endDate | date }}
        </template>
      </date-range-picker>
    </div>

    <div class="ta-auction-category-filter my-4">
      <input-list-group :title="$t('Countries')"
                        :options="prepareOptions(auctionFilterDetails.countries)"
                        v-model="filters.country"
                        @change="filtersChanged"></input-list-group>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import InputListGroup from "./InputListGroup";
import DateRangePicker from "../plugins/src/components/DateRangePicker";
import {assemble_filter} from "../helpers";

export default {
  name: "AuctionFilters",
  components: {DateRangePicker, InputListGroup},
  data() {
    return {
      filters: {
        department: null,
        category: null,
        country: null,
        status: null,
      },
      startDateRange: {
        startDate: null,
        endDate: null
      },

      endDateRange: {
        endDateFrom: null,
        endDateFromTo: null
      },
    }
  },

  computed: {
    ...mapGetters(['currentLocale', 'auctionFilterDetails'])
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        for (let key in this.filters) {
          if (newVal[key]) {
            switch (key) {
              case 'start_date_from':
                this.startDateRange.startDate = newVal[key];
                break;
              case 'start_date_to':
                this.startDateRange.endDate = newVal[key];
                break;
              case 'end_date_from':
                this.endDateRange.startDate = newVal[key];
                break;
              case 'end_date_to':
                this.endDateRange.endDate = newVal[key];
                break;
              default:
                this.filters[key] = newVal[key];
            }
          }
        }
      }
    }
  },

  methods: {
    ...mapActions(['getAuctions', 'setAuctionFilters']),

    filtersChanged() {
      this.$emit('change', assemble_filter({
        filters: this.filters,
        startDateRange: this.startDateRange,
        endDateRange: this.endDateRange
      }));
    },
    prepareOptions(filterValue) {
      let options = [];
      if (!filterValue) {
        return options;
      }

      for (const value of filterValue) {
        options.push({
          value: value.key ?? value.code,
          text: `${value.name[this.currentLocale]} (${value.items_count} ${this.$t('Sales')})`,
        })
      }
      return options;
    },

    clearFilters() {
      Object.keys(this.filters).forEach((index) => {
        this.filters[index] = null;
      });

      this.startDateRange = this.endDateRange = {
        startDate: null,
        endDate: null
      };

      this.filtersChanged();
    },
  },
}
</script>

<style scoped lang="scss">
#artisioTimedAuctions {
  .ta-auction-filters {
    width: 300px;
    max-width: 300px;
  }

  .date-control {
    width: auto;
    flex: 1;
  }
}
</style>
