<template>
  <b-pagination
    v-if="isPagingTypePagination && lots.totalRows > lotQueryParams.limit"
    v-model="page"
    :total-rows="lots.totalRows"
    :per-page="lotQueryParams.limit"
    @input="updatePagination"
    class="ta-lot-list-pagination"
  ></b-pagination>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {updateRoute} from "../../../helpers";
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";

export default {
  name: "LotListPaginationButtons",
  data: () => ({
    page: LOT_LIST_DEFAULT_PAGE,
  }),
  computed: {
    ...mapState(['lots', 'lotQueryParams']),
    ...mapGetters(['isPagingTypePagination'])
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    updatePagination() {
      this.setLotFilters({page: this.page});
      updateRoute();
    },
  },
  mounted() {
    this.page = this.lotQueryParams.page;
  }
}
</script>

<style scoped>

</style>
