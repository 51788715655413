<template>
  <small v-if="mainConfig.enableBidding" class="text-muted">{{ lot.num_of_bids || 0 }} {{ $t('bids') }}</small>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotNumberOfBids",
  props: {
    lot: {
      required: true,
      type: Object
    },
  },
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style scoped>

</style>
