<template>
  <small class="text-nowrap d-block mb-1">
    {{ $t('Lot') }}:
    <span class="ta-lot-ID text-primary font-weight-bold">{{ lot.lot_no | lotno }}</span>
  </small>
</template>

<script>
export default {
  name: "LotNo",
  props: {
    lot: {
      required: true,
      type: Object
    },
  },
}
</script>

<style scoped>

</style>
