import moment from "moment";
import { LOT_DEFAULT_SORT_BY, LOT_DEFAULT_SORT_FIELD, LOT_LIST_DEFAULT_LIMIT, LOT_LIST_DEFAULT_PAGE } from "./constants";
import router from "./router";
import store from "./store";

export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function assemble_filter(params) {
  params.filters["start_date_from"] = params.startDateRange.startDate
    ? moment(params.startDateRange.startDate).format("YYYY-MM-DD")
    : null;
  params.filters["start_date_to"] = params.startDateRange.endDate
    ? moment(params.startDateRange.endDate).format("YYYY-MM-DD")
    : null;

  params.filters["end_date_from"] = params.endDateRange.startDate
    ? moment(params.endDateRange.startDate).format("YYYY-MM-DD")
    : null;
  params.filters["end_date_to"] = params.endDateRange.endDate
    ? moment(params.endDateRange.endDate).format("YYYY-MM-DD")
    : null;

  return params.filters;
}

export function getVuexFilters() {
  const state = store.state;
  const params = {};
  for (let key in state.lotQueryParams) {
    if (!state.lotQueryParams[key]) {
      continue;
    }
    if (
      Array.isArray(state.lotQueryParams[key]) &&
      !state.lotQueryParams[key].length
    ) {
      continue;
    }
    params[key] = state.lotQueryParams[key];
  }
  return params;
}

export function updateRoute() {
  const filters = getVuexFilters();
  const query = {
    ...filters
  }
  let params = {};
  if (query.auction_uuid) {
    params.auction_uuid = query.auction_uuid;
    delete query.auction_uuid;
  }

  if (query.page == LOT_LIST_DEFAULT_PAGE) {
    delete query.page;
  }
  if (query.limit == LOT_LIST_DEFAULT_LIMIT) {
    delete query.limit;
  }
  if (query.sort == LOT_DEFAULT_SORT_FIELD) {
    delete query.sort;
  }
  if (query['sort-by'] == LOT_DEFAULT_SORT_BY) {
    delete query['sort-by'];
  }
  if (!store.getters.isPagingTypePagination) {
    delete query.page
  }
  router.push({
    path: router.app.$route.path,
    params,
    query
  });
}

export function hideHTMLElements(cssSelector) {
  const elements = document.querySelectorAll(cssSelector);
  elements.forEach(el => el.style.display = 'none')
}

export function showHTMLElements(cssSelector) {
  const elements = document.querySelectorAll(cssSelector);
  elements.forEach(el => el.style.display = '')
}

export function deepClone(object) {
  if (typeof object === 'object')
    return JSON.parse(JSON.stringify(object));

  throw "Variable must be of 'object' type";
}
