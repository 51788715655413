<template>
  <h4 class="ta-lot-list-item-title font-weight-bold">
    <router-link :to="lotInnerPageRoute">
      {{ $options.filters.currentLocale(lot.title) | stripTags }}
    </router-link>
  </h4>
</template>

<script>
export default {
  name: "LotListItemTitle",
  props: {
    lot: {
      required: true,
      type: Object
    },
    lotInnerPageRoute: [String, Object]
  },
}
</script>

<style scoped>

</style>
