import i18n from "../i18n";
import {currentLocale} from "../filters/currentLocale";
import {PAGING_TYPE_LOAD_MORE, PAGING_TYPE_PAGINATION, PAGING_TYPE_SCROLL} from "../constants";

export default {
  currentLocale: () => i18n.locale,
  fallbackLocale: () => i18n.fallbackLocale,
  dateFormat: () => 'DD MMM YYYY',
  datetimeFormat: () => 'DD MMM YYYY HH:mm',
  auctionFilterDetails(state) {
    const auction = state.auctions;
    return {
      'departments': auction.departments,
      'categories': auction.categories,
      'countries': auction.countries,
    }
  },
  lotObjectData(state) {
    return state.lotObject.data;
  },

  customerMeta(state) {
    return state.customer;
  },

  lotFilterDetails(state) {
    const lot = state.lots;
    return {
      'departments': lot.departments,
      'categories': lot.categories,
    }
  },
  selectedDepartment(state) {
    return state.departments.data.find(d => state.lotQueryParams.department.includes(d.key))
  },

  dynamicFields(state) {
    const fields = [];
    for (let key in state.dynamicFields.data) {
      fields.push({
        key,
        ...state.dynamicFields.data[key],
      });
    }
    return fields;
  },

  filterableDynamicFields(state) {
    const fields = [];
    for (let key in state.dynamicFields.data) {
      fields.push({
        key,
        ...state.dynamicFields.data[key],
      });
    }
    return fields.filter(f => !!f.filterType).sort((a, b) => a.position - b.position);
  },

  categoriesAsOptions(state) {
    return state.categories.data.map(op => ({
      value: op.key ?? op.code,
      text: currentLocale(op.name),
      count: op.items_count,
    }))
  },

  is_authorized(state) {
    return state.is_authorized;
  },

  isPagingTypePagination(state) {
    return state.mainConfig.paginationType === PAGING_TYPE_PAGINATION;
  },
  isPagingTypeLoadMore (state) {
    return state.mainConfig.paginationType === PAGING_TYPE_LOAD_MORE;
  },
  isPagingTypeScroll(state) {
    return state.mainConfig.paginationType === PAGING_TYPE_SCROLL;
  }
}
