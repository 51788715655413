<template>
  <div class="ta-total-items-found">
    {{$t('{total} items found', {total: lots.totalRows})}}
  </div >
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotListItemsCount",
  computed: {
    ...mapState(['lots'])
  }
}
</script>

<style lang="scss" scoped>
  .ta-total-items-found {
    display: inline-flex;
    font-weight: bold;
  }
</style>
