<template>
  <b-form-group class="ta-auction-category-filter px-2">
    <div class="ta-input-list-group-item">
      <div v-for="dep of departments" :key="dep.key" >
        <div v-if="value && value.includes(dep.key)" class="font-weight-semibold">
          {{ dep.name | currentLocale }} <small>({{dep.items_count}})</small>
        </div>
        <a v-else href="javascript:void(0)" @click="selectDepartment(dep)" class="d-block text-dark">
          {{ dep.name | currentLocale }} <small>({{dep.items_count}})</small>
        </a>
      </div>
    </div>
  </b-form-group>
</template>

<script>
export default {
  name: "DepartmentList",
  props: {
    departments: Array,
    value: [String, Array],
  },
  methods: {
    selectDepartment(dep) {
      this.$emit("input", [dep.key]);
      this.$emit("change", [dep.key]);
    },
  },
};
</script>

<style></style>
