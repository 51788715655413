
<script>
import {mapState} from "vuex";
import Vue from "vue";
import {TEMPLATE_KEYWORD_COMPONENT_MAP} from '../../constants'

export default {
  name: "LotFilterList",
  computed: {
    ...mapState(['mainConfig'])
  },
  watch: {
    'mainConfig.templates.lotFiltersTemplate': {
      immediate: true,
      handler() {
        let TEMPLATE = `
          <div>
            ${this.mainConfig.templates.lotFiltersTemplate}
          </div>
        `;
        for (let key in TEMPLATE_KEYWORD_COMPONENT_MAP) {
          TEMPLATE = TEMPLATE.replace(`{{${key}}}`, TEMPLATE_KEYWORD_COMPONENT_MAP[key])
        }
        var res = Vue.compile(TEMPLATE);

        this.templateRender = res.render;

        // staticRenderFns belong into $options,
        // appearantly
        this.$options.staticRenderFns = []

        // clean the cache of static elements
        // this is a cache with the results from the staticRenderFns
        this._staticTrees = []

        // Fill it with the new staticRenderFns
        for (var i in res.staticRenderFns) {
          //staticRenderFns.push(res.staticRenderFns[i]);
          this.$options.staticRenderFns.push(res.staticRenderFns[i])
        }
      }
    }
  },
  render(h, context) {
    if (!this.templateRender) {
      return h('div', 'Loading...')
    }
    return this.templateRender();
  }
}
</script>

<style scoped>

</style>
