<template>
  <b-overlay :show="loading" rounded="sm">
  <div class="ta-page-invoices">
    <h1>{{ $t("Invoices ") }}</h1>
    <div class="d-flex justify-content-start">
      <div class="ta-invoice-list">
        <div class="ta-invoice-list-header">
          <div class="ta-per-page-wrapper">
            {{ $t("Show") }}
            <b-form-select
              class="mx-2"
              v-model="limit"
              @change="updatePagination"
              :options="perPageOptions"
            ></b-form-select>
            {{ $t("entries") }}
          </div>
          <div class="ta-invoice-search-wrapper d-flex align-items-center">
            {{ $t("Search") }}:
            <b-form-input
              :placeholder="$t('Search for invoices')"
              class="ml-2"
              v-model="filters.q"
              v-debounce:300ms="updateRoute"
            ></b-form-input>
          </div>
        </div>
        <div v-if="invoices.loading" class="ta-invoice-loader">
          <b-spinner label="Spinning"></b-spinner>
          <span class="mt-2">{{ $t("Loading...") }}</span>
        </div>
        <template v-if="!invoices.loading">
          <div class="ta-invoice-list-wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">
                    {{ $t("Invoice No") }}
                  </th>
                  <th scope="col">
                    {{ $t("Sale") }}
                  </th>
                  <th scope="col">
                    {{ $t("Date") }}
                  </th>
                  <th scope="col">
                    {{ $t("Total Amount/Total Outstanding") }}
                  </th>
                  <th scope="col">
                    {{ $t("Status") }}
                  </th>
                  <th scope="col">
                    {{ $t("Shipping Status") }}
                  </th>
                  <th scope="col">
                    {{ $t("Actions") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="invoice in invoices.results" :key="invoice.id">
                  <td>
                    <a href="javascript:void(0)" class="btn-invoice-view" @click="view(invoice)">
                      {{ invoice.invoice_no }}
                    </a>
                  </td>
                  <td>
                    {{ invoice.auction.sale_no }}
                  </td>
                  <td>
                    {{ invoice.date | datetime }}
                  </td>
                  <td>
                    {{ invoice.total_amount | asCurrency(invoice.currency.code) }}/{{ invoice.outstanding_amount | asCurrency(invoice.currency.code) }}
                  </td>
                  <td>
                    <span
                      class="badge ta-uc-first"
                      :class="{
                        'badge-warning': invoice.status === 'unpaid',
                        'badge-danger': invoice.status === 'cancelled',
                        'badge-success': invoice.status === 'paid',
                      }"
                      >{{ invoice.status }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="badge badge-info"
                      v-if="invoice.is_dispatched"
                      >{{ $t("Dispatched") }}</span
                    >
                    <span
                      class="badge badge-success ta-uc-first"
                      v-else-if="invoice.shipping_status === 'paid'"
                      >{{ invoice.shipping_status }}</span
                    >
                    <span
                      class="badge badge-danger"
                      v-else-if="invoice.shipping_status === 'unpaid'"
                      >{{ invoice.shipping_status }}</span
                    >
                    <span class="badge badge-secondary" v-else>N/A</span>
                  </td>
                  <td class="text-nowrap">
                    <b-dropdown :disabled="invoice.status !== 'unpaid'" :text="$t('Pay')" variant="primary" size="sm" class="btn-invoice-pay mr-2">
                      <b-dropdown-item v-for="provider in invoice.payment_providers" :key="provider.name" @click="getInvoiceUrl(invoice.uuid, provider.name)">
                        {{ provider.name[0].toUpperCase() + provider.name.slice(1) }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <button class="btn btn-sm btn-primary btn-pdf-view mr-2" @click="view(invoice)">
                      {{ $t("View") }}
                    </button>
                    <button class="btn btn-sm btn-primary btn-pdf-download" @click="download(invoice)">
                      {{ $t("Download") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="ta-invoice-list-footer">
            <div class="ta-invoice-pagination-summary">
              {{
                $t("Showing {start} to {finish} of {total} entries", {
                  start:
                    (invoices.pagination.page - 1) * invoices.pagination.limit +
                    1,
                  finish:
                    invoices.pagination.page * invoices.pagination.limit >
                    invoices.totalRows
                      ? invoices.totalRows
                      : invoices.pagination.page * invoices.pagination.limit,
                  total: invoices.totalRows,
                })
              }}
            </div>
            <b-pagination
              v-model="page"
              :total-rows="invoices.totalRows"
              :per-page="invoices.pagination.limit"
              @input="updatePagination"
              class="ta-invoice-list-pagination"
            ></b-pagination>
          </div>
        </template>
      </div>
    </div>
     <b-modal
      :title="modal.title"
      v-model="modal.show"
      size="xl"
      static
      :ok-only="true"
      lazy
    >
        <iframe class="ta-base64-viewer-iframe" :src="modal.base64"></iframe>
    </b-modal>
  </div>
  </b-overlay>
</template>

<script>
import i18n from '@/i18n';
import { mapState, mapGetters, mapActions } from "vuex";
import {
  INVOICE_PAGINATION_PAGE,
  INVOICE_PAGINATION_LIMIT,
} from "../constants";
import logger from "../logger";

export default {
  name: "Invoices",
  components: {
  },
  data: () => {
    return {
      limit: INVOICE_PAGINATION_LIMIT,
      page: INVOICE_PAGINATION_PAGE,
      filters: {
        q: null,
      },
      modal: {
        show: false,
        title: '',
        base64: ''
      },
      delayedRequest: null,
      query: null,
      normalizedQuery: null,
      loading: false,
    };
  },

  computed: {
    ...mapState(["perPageOptions", "invoices"]),
    ...mapGetters(["currentLocale"]),
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newVal) {
        // make actions with newVal.page
        if (newVal.page) this.page = newVal.page;
        if (newVal.limit) this.limit = newVal.limit;

        for (const key in this.filters) {
          if (newVal[key]) this.filters[key] = newVal[key];
        }

        this.getUpdatedInvoices();
      },
    },
  },

  methods: {
    ...mapActions(["getInvoices", "setInvoicePagination", "getInvoicePdf", "requestPaymentUrl"]),

    filtersChanged(filters) {
      this.filters = filters;
      this.updateRoute();
    },

    updatePagination() {
      this.updateRoute();
    },

    updateRoute() {
      this.$router
        .push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: this.page,
            limit: this.limit,
            ...this.filters,
          },
        })
        .catch((err) => {
          logger.error("Failed to update route for invoices", err);
        });
    },

    getUpdatedInvoices() {
      this.getInvoices({
        pagination: { page: this.page, limit: this.limit },
        filters: this.filters,
      });
    },
    async download(invoice) {
      const result = await this.getInvoicePdf({uuid: invoice.uuid});
      if (result) {
        const link = document.createElement('a');
        link.href = `data:application/pdf;name=${invoice.invoice_no}.pdf;base64,` + result;
        link.download = `${invoice.invoice_no}.pdf`;
        link.style.display = 'none';
        document.body.appendChild(link);
        console.log(link);
        link.click();
      } 
    },
    async view(invoice) {
      const result = await this.getInvoicePdf({uuid: invoice.uuid});
      if (result) {
        this.modal.base64 = `data:application/pdf;name=${invoice.invoice_no}.pdf;base64,` + result;
        this.modal.title = `${invoice.invoice_no}.pdf`;
        this.modal.show = true;
      } 
    },
    async getInvoiceUrl(invoiceUuid, providerName) {
      this.loading = true;
      this.requestPaymentUrl({invoiceUuid, providerName}).then((url) => {
        window.location.href = url;
      }).finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style scoped lang="scss">
.ta-invoice-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ta-invoice-loader {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ta-per-page-wrapper {
  display: flex;
  align-items: center;
}

.ta-invoice-list {
  flex: 1;
}

.ta-invoice-list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-uc-first {
  text-transform: capitalize;
}

.pagination.ta-invoice-list-pagination {
  margin-bottom: 0 !important;
}

.ta-base64-viewer-iframe {
  width: 100%;
  min-height: 600px;
}
</style>
