<template>
  <div v-if="mainConfig.showLatestBids" class="ta-lot-latest-bids card">
    <table class="table table-sm table-bordered mb-0">
      <thead>
      <tr>
        <th colspan="3">
          {{ $t("Last 5 Bids") }}
        </th>
      </tr>
      </thead>
      <tbody v-if="lot.last_bids">
      <tr v-for="bid in lot.last_bids" :key="bid.id">
        <td>{{ bid.paddle_no }}</td>
        <td>{{ bid.amount | asCurrency(lot.currency.code) }}</td>
        <td>{{ bid.bid_date |datetime }}</td>
      </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="3" class="text-muted text-center py-3">
            {{$t('There are no bids on this lot')}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LatestBids",
  props: {
    lot: Object
  },
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style scoped lang="scss">
.ta-lot-latest-bids {
  font-size: 90%;
}
</style>
