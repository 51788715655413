<template>
  <div>
    <button class="btn btn-primary btn-block" @click="showHideModel">
      {{ $t('Make an Offer') }}
    </button>
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <form @submit.stop.prevent="onSubmit">
        <b-modal
          ref="makeAnOfferModal"
          :title="$t('Make an Offer')"
          v-model="showModal"
          :ok-title="$t('Submit')"
          :no-close-on-esc="actionsDisabled"
          :no-close-on-backdrop="actionsDisabled"
          @hide="actionsDisabled ? $event.preventDefault() : null"
          @show="onModalShow"
          @hidden="onModalHide"
          static
          lazy
        >
          <div v-if="offers.length" class="d-flex">
            <strong class="m-0">{{$t('Your last offer')}}</strong>: &nbsp;<span>{{offers[0].amount}}</span>
            <hr>
          </div>
          <InputWidget
            id="make-an-offer-input-amount"
            type="number"
            v-model="amount"
            :error="errors.amount"
            :label="$t('Your Amount')"
            rules="required"
            :placeholder="$t('Enter Your Amount')"
          >
          </InputWidget>
          <template #modal-footer>
            <div class="d-flex justify-content-end">
              <b-button variant="secondary" @click="showModal = false" :disabled="actionsDisabled">
                {{ $t('Cancel') }}
              </b-button>
              <loading-button class="ml-2"
                              :loading="actionsDisabled"
                              :absolute="false"
                              :disabled="invalid || actionsDisabled">
                {{ $t('Submit') }}
              </loading-button>
            </div>
          </template>
        </b-modal>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import InputWidget from "../../core/InputWidget";
import {mapActions, mapMutations} from "vuex";
import {submitMakeAnOffer} from "../../../store/actions";
import LoadingButton from "../../core/LoadingButton";
import i18n from "../../../i18n";
import {asCurrency} from "../../../filters/currency";
import axiosClient from "../../../services/axios";

export default {
  name: "MakeAnOfferButton",
  components: {LoadingButton, InputWidget},
  props: ['lot'],
  data: () => ({
    amount: null,
    showModal: false,
    errors: {
      amount: null
    },
    actionsDisabled: false,
    offers: []
  }),
  methods: {
    ...mapActions(['submitMakeAnOffer']),
    ...mapMutations(['showNotification']),
    showHideModel() {
      this.showModal = !this.showModal;
    },
    onSubmit(ev) {
      ev.preventDefault();
      this.actionsDisabled = true;
      this.submitMakeAnOffer({lot_uuid: this.lot.uuid, amount: parseFloat(this.amount)})
        .then(() => {
          // Show notification
          this.showNotification({
            title: i18n.t('Thanks for your offer.'),
            text: i18n.t("You made an offer for {amount}.", {amount: this.$options.filters.asCurrency(this.amount, this.lot.currency.code)}),
            variant: 'success',
            duration: 5000
          });
          this.showModal = false;
          this.actionsDisabled = false;
        })
        .catch(error => {
          const data = error.response.data;
          this.errors = data.errors;
          this.actionsDisabled = false;
        })
    },
    async onModalShow() {
      try {
        const {data} = await axiosClient.get(`website/user-offer-commission/${this.lot.uuid}`);
        this.offers = data.result.sort((a, b) => b.amount - a.amount);
      } catch(e) {
        this.offers = [];
      }
    },
    onModalHide() {
      this.$refs.form.reset();
      this.errors = {
        amount: null
      };
      this.amount = null;
      this.actionsDisabled = false
    }
  }
}
</script>

<style scoped>

</style>
