<template>
  <b-modal v-model="modalMsgBox.visible" :title="modalMsgBox.title"
           :header-bg-variant="modalMsgBox.headerBgVariant"
           :header-text-variant="modalMsgBox.headerTextVariant"
           ok-only ok-variant="secondary" ok-title="Close"
           static centered>
    <span v-html="modalMsgBox.content"></span>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AtaMsgModal",

  computed: {
    ...mapState(['modalMsgBox'])
  },
}
</script>

<style scoped>

</style>