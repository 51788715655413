<template>
  <button class="btn btn-switch-view d-none d-sm-inline-block" @click="switchView">
    <svg v-if="lotListViewType === 'list'" xmlns="http://www.w3.org/2000/svg" style="width: 24px" fill="none"
         viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
    </svg>
    <svg v-if="lotListViewType === 'grid'" xmlns="http://www.w3.org/2000/svg" style="width: 24px" fill="none"
         viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M4 6h16M4 10h16M4 14h16M4 18h16"/>
    </svg>
  </button>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "LotListSwitchView",
  computed: {
    ...mapState(['lotListViewType'])
  },
  methods: {
    ...mapMutations(['changeLotView']),
    switchView() {
      this.changeLotView(this.lotListViewType === 'grid' ? 'list' : 'grid');
    },
  }
}
</script>

<style scoped>

</style>
