<template>
  <div v-if="isPagingTypePagination" class="ta-lot-pagination-summary">
    {{
      $t('Showing {start} to {finish} of {total} entries',
        {
          start: (lotQueryParams.page - 1) * lotQueryParams.limit + 1,
          finish: Math.min(lotQueryParams.page * lotQueryParams.limit, lots.totalRows),
          total: lots.totalRows
        })
    }}
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "LotListPaginationSummary",
  computed: {
    ...mapState(['lots', 'lotQueryParams']),
    ...mapGetters(['isPagingTypePagination'])
  }
}
</script>

<style scoped>

</style>
