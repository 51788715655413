<template>
  <div v-if="isPagingTypeLoadMore" class="ta-load-more-wrapper mt-3 text-center">
    <button class="btn btn-primary" @click="updatePagination">
      {{ $t('Load More') }}
    </button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {updateRoute} from "../../../helpers";

export default {
  name: "LotListLoadMore",
  computed: {
    ...mapState(['lotQueryParams']),
    ...mapGetters(['isPagingTypeLoadMore'])
  },
  methods: {
    ...mapActions(['getLots']),
    ...mapMutations(['setLotFilters', 'setPreserveOldLots']),
    updatePagination() {
      this.setPreserveOldLots(true);
      this.setLotFilters({page: parseInt(this.lotQueryParams.page) + 1});
      this.getLots();
    }
  }
}
</script>

<style scoped>

</style>
