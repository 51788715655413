<template>
  <div v-if="lot.buy_now_price" class="">
    <div>
      {{ $t('Buy now') }}
    </div>
    <span class="ta-lot-list-item-price">
      {{ lot.buy_now_price | asCurrency(lot.currency.code) }}
    </span>
  </div>
</template>

<script>
export default {
  name: "LotBuyNowPrice",
  props: {
    lot: {
      required: true,
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
