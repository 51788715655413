<template>
  <small class="text-nowrap d-block mb-1">
    {{ $t('Lot') }}:
    <span class="ta-lot-ID text-primary font-weight-bold">{{ lot.lot_no | lotno }}</span>
  </small>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotViewLotNo",
  computed: {
    ...mapState({
      lot: state => state.lotObject.data
    }),
  }
}
</script>

<style scoped>

</style>
