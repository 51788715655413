<template>
  <div v-if="images.carousel.length > 0">
    <carousel :images="images.carousel" @showGallery="openGallery"/>
    <GallerySlideshow :images="images.gallery" :index="gallery_idx" @close="gallery_idx = null"/>
  </div>
  <div v-else>
    <img class="img-fluid"
         src="https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg">
  </div>
</template>

<script>
import Carousel from "../../Carousel";
import GallerySlideshow from "../../../plugins/vue-gallery-slideshow/src/component/GallerySlideshow";
import {mapState} from "vuex";

export default {
  name: "LotViewImages",
  components: {GallerySlideshow, Carousel},
  data: () => ({
    gallery_idx: null
  }),
  computed: {
    ...mapState({
      lot: state => state.lotObject.data
    }),
    images() {
      const lot_images = this.lot.images;

      let carousel = [];
      let gallery = [];

      let id = 0;
      for (const image of lot_images) {
        carousel.push({
          id: ++id,
          big: (image.lg || image.original).url,
          small: (image.sm || image.original).url,
          thumb: (image.thumb || image.original).url
        });
        gallery.push((image.lg || image.original).url);
      }

      return {
        carousel,
        gallery
      };
    },
  },
  methods: {
    openGallery(idx) {
      this.gallery_idx = idx;
    },
  }
}
</script>

<style scoped>

</style>
