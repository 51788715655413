<template>
  <div
    v-if="!mainConfig.descriptionDynamicFields"
    class="ta-lot-description"
    v-html="$options.filters.currentLocale(lot.description)"
  >
  </div>
  <div v-else>
    <template v-for="field of Object.keys(dynamicFields)">
      <div v-if="dynamicFields[field]" :key="field" class="dynamic-field-item">
        <h5 class="dynamic-field-label">
          {{ $t(`dynamic_field.${field}`) }}
        </h5>
        <div class="dynamic-field-value" v-html="dynamicFields[field]">
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import i18n from '../../../i18n';
import CONFIG from "../../../user-config";
import {mapState} from "vuex";

export default {
  name: 'LotViewDescription',
  props: {
    lot: Object
  },
  computed: {
    ...mapState(['mainConfig']),
    dynamicFields() {
      const dynamicFields = CONFIG.descriptionDynamicFields;
      const fieldsObj = this.lot.dynamic_fields[i18n.locale] || this.lot.dynamic_fields[i18n.fallbackLocale];
      if (dynamicFields === true) {
        return fieldsObj;
      }
      if (dynamicFields === false) {
        return {};
      }

      return Object.fromEntries(Object.entries(fieldsObj).filter(([key, value]) => dynamicFields.includes(key)))
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../bootstrap/import";

/deep/ .dynamic-field-item {
  margin-bottom: 1.5rem;
}

#artisioTimedAuctions {
  .ta-lot-description {
    /deep/ {
      h1, h2, h3, h4 {
        margin-top: 2rem;
        color: $primary
      }

      table {
        width: 100%;
        background-color: $gray-100;

        > tbody {
          > tr {
            > td {
              padding: 0.25rem 0.5rem;
              border: 1px solid $gray-300
            }
          }
        }
      }

      .artisio-youtube-video {
        position: relative;
        width: 100%;
        margin-top: 2rem;
        padding-top: 54%;

        iframe {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
        }
      }
    }
  }
}
</style>
