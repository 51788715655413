<template>
  <div v-if="mainConfig.showCollectionInfo && lot.collection_info.city" class="ta-collection-info font-weight-semibold mb-3">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd"
            d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
            clip-rule="evenodd"/>
    </svg>
    <span class="ml-1">
        {{ lot.collection_info.zip_code }} {{ lot.collection_info.state }} {{ lot.collection_info.city }}
      </span>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotCollectionInformation",
  props: {
    lot: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style lang="scss" scoped>

.ta-collection-info {
  line-height: 1.25rem;

  svg {
    width: 20px;
    min-width: 20px;
  }
}

</style>
