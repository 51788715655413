<template>
  <div>
    <div class="row">
      <div class="col-mg-12 col-xl-10  offset-xl-1">
        <div class="ta-home-departments">
          <router-link :to="{name: 'Lots', query:{department: dep.key}}" v-for="dep of departments.data" :key="dep.key"
                       class="col-lg-2 col-md-3 col-sm-4 col-sm-6 ta-home-department-item text-dark">
            <img :src="'https://www.freeiconspng.com/thumbs/car-icon-png/car-icon-png-25.png'"
                 class="ta-home-department-img">
            {{ dep.name | currentLocale }}
          </router-link>
        </div>
      </div>
    </div>

    <lot-list-items></lot-list-items>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {getDepartmentsForHome} from "../store/actions";

export default {
  name: "Home",
  computed: {
    ...mapState({
      departments: state => state.homePage.departments
    })
  },
  methods: {
    ...mapActions(['getLots', 'getDepartmentsForHome']),
  },
  mounted() {
    this.getDepartmentsForHome();
    this.getLots()
  }

}
</script>

<style lang="scss" scoped>
@import "../bootstrap/import";

#artisioTimedAuctions {

  .ta-home-departments {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .ta-home-department-item {
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s;
    border: 1px solid transparent;
    border-radius: $border-radius;

    &:hover {
      background-color: fade-out($primary, 0.95);
      border: 1px solid fade-out($primary, 0.65);
    }
  }

  .ta-home-department-img {
    width: 50px;
    margin-bottom: 1rem;
  }
}

</style>
