import Vue from 'vue';

import LotAddToWatchlist from './lot-list-item/LotAddToWatchlist.vue'
import LotCollectionInformation from './lot-list-item/LotCollectionInformation.vue'
import LotCountdownTimer from './lot-list-item/LotCountdownTimer.vue'
import LotActualPrice from './lot-list-item/LotActualPrice.vue'
import LotBuyNowPrice from './lot-list-item/LotBuyNowPrice.vue'
import LotListItemImage from './lot-list-item/LotListItemImage.vue'
import LotListItemTitle from './lot-list-item/LotListItemTitle.vue'
import LotNo from './lot-list-item/LotNo.vue'
import LotStartPrice from './lot-list-item/LotStartPrice.vue'
import LotNumberOfBids from './lot-list-item/LotNumberOfBids.vue'

import FilterCategoryList from './lot-filters/FilterCategoryList.vue'
import FilterDepartmentList from './lot-filters/FilterDepartmentList.vue'
import FilterDynamicFields from './lot-filters/FilterDynamicFields.vue'
import FilterEstimateRange from './lot-filters/FilterEstimateRange.vue'
import FilterLotRange from './lot-filters/FilterLotRange.vue'
import FilterReserveRange from './lot-filters/FilterReserveRange.vue'
import FilterPriceRange from './lot-filters/FilterPriceRange.vue'
import FilterWithImage from './lot-filters/FilterWithImage.vue'

import JumpToLot from './lot-list/JumpToLot.vue'
import LotListFilterMobileButton from './lot-list/LotListFilterMobileButton.vue'
import LotListFilters from './lot-list/LotListFilters.vue'
import LotListItems from './lot-list/LotListItems.vue'
import LotListPaginationButtons from './lot-list/LotListPaginationButtons.vue'
import LotListPaginationSummary from './lot-list/LotListPaginationSummary.vue'
import LotListPerPageSelect from './lot-list/LotListPerPageSelect.vue'
import LotListSwitchView from './lot-list/LotListSwitchView.vue'
import SortingDropdown from './lot-list/SortingDropdown.vue'
import LotKeywordSearch from './lot-list/LotKeywordSearch.vue'
import LotListItemsCount from './lot-list/LotListItemsCount.vue'

import LotViewBidding from './lot-view/LotViewBidding.vue';
import LotViewBreadcrumbs from './lot-view/LotViewBreadcrumbs.vue';
import LotViewImages from './lot-view/LotViewImages.vue';
import LotViewJumpToLot from './lot-view/LotViewJumpToLot.vue';
import LotViewLotNo from './lot-view/LotViewLotNo.vue';
import LotViewPrevNextButtons from './lot-view/LotViewPrevNextButtons.vue';
import LotViewSocialShare from './lot-view/LotViewSocialShare.vue';
import LotViewTitle from './lot-view/LotViewTitle.vue';
import LotViewHeader from './lot-view/LotViewHeader.vue';
import LotViewDescription from './lot-view/LotViewDescription.vue';
import MakeAnOfferButton from './lot-view/MakeAnOfferButton.vue';
import MakeAnEnquiryButton from './lot-view/MakeAnEnquiryButton.vue';

Vue.component('LotAddToWatchlist', LotAddToWatchlist)
Vue.component('LotCollectionInformation', LotCollectionInformation)
Vue.component('LotCountdownTimer', LotCountdownTimer)
Vue.component('LotActualPrice', LotActualPrice)
Vue.component('LotBuyNowPrice', LotBuyNowPrice)
Vue.component('LotListItemImage', LotListItemImage)
Vue.component('LotListItemTitle', LotListItemTitle)
Vue.component('LotNo', LotNo)
Vue.component('LotStartPrice', LotStartPrice)
Vue.component('LotNumberOfBids', LotNumberOfBids)

Vue.component('FilterCategoryList', FilterCategoryList);
Vue.component('FilterDepartmentList', FilterDepartmentList);
Vue.component('FilterDynamicFields', FilterDynamicFields);
Vue.component('FilterEstimateRange', FilterEstimateRange);
Vue.component('FilterLotRange', FilterLotRange);
Vue.component('FilterPriceRange', FilterPriceRange);
Vue.component('FilterReserveRange', FilterReserveRange);
Vue.component('FilterWithImage', FilterWithImage);

Vue.component('JumpToLot', JumpToLot)
Vue.component('LotListFilterMobileButton', LotListFilterMobileButton)
Vue.component('LotListFilters', LotListFilters)
Vue.component('LotListItems', LotListItems)
Vue.component('LotListPaginationButtons', LotListPaginationButtons)
Vue.component('LotListPaginationSummary', LotListPaginationSummary)
Vue.component('LotListPerPageSelect', LotListPerPageSelect)
Vue.component('LotListSwitchView', LotListSwitchView)
Vue.component('SortingDropdown', SortingDropdown)
Vue.component('LotKeywordSearch', LotKeywordSearch)
Vue.component('LotListItemsCount', LotListItemsCount)

Vue.component('LotViewBidding', LotViewBidding);
Vue.component('LotViewBreadcrumbs', LotViewBreadcrumbs);
Vue.component('LotViewImages', LotViewImages);
Vue.component('LotViewJumpToLot', LotViewJumpToLot);
Vue.component('LotViewLotNo', LotViewLotNo);
Vue.component('LotViewPrevNextButtons', LotViewPrevNextButtons);
Vue.component('LotViewSocialShare', LotViewSocialShare);
Vue.component('LotViewTitle', LotViewTitle);
Vue.component('LotViewHeader', LotViewHeader);
Vue.component('LotViewDescription', LotViewDescription);
Vue.component('MakeAnOfferButton', MakeAnOfferButton);
Vue.component('MakeAnEnquiryButton', MakeAnEnquiryButton);
