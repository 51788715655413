<template>
  <page-component class="ta-auction-view">
    <div v-if="auctionObject.loading" class="ta-auction-view-loader">
      <b-spinner label="Spinning"></b-spinner>
      <span class="mt-2">{{ $t("Loading...") }}</span>
    </div>
    <template v-else>
      <div class="mb-5">
        <div class="row">
          <!-- <div class="col-sm-3">
            <b-img :src="auction.image_url" fluid></b-img>
          </div> -->
          <div class="col-sm">
            <div>
              <h1 class="ta-auction-view-title d-inline">
                <!-- {{ auction.auction_no }} - -->
                {{ auction.title | currentLocale }}
              </h1>
              <!-- <b-btn v-if="auction.bidder_approval && !auction.is_bidder && is_authorized"
                     @click="registerAsBidder(auction.uuid);" ref="registerAsBidderBtn"
                     class="mt-1 float-right"
                     variant="success">
                <b-icon-person-plus-fill></b-icon-person-plus-fill>
                {{ $t('Register as bidder') }}
              </b-btn> -->
            </div>

            <b-tabs content-class="mt-3 mb-5" align="right">
              <b-tab :title="$t('Auction Details')" active>
                <auction-details :auction="auction" />
              </b-tab>
              <b-tab :title="$t('Auction Premiums')">
                <auction-buyer-premiums :auction="auction" />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
      <lot-list
        :auction-uuid="auction.uuid"
        :rendered-from-auction-view="true"
      ></lot-list>
    </template>
  </page-component>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import LotList from "../components/LotList";
import auctionDates from "../components/auction-view/AuctionDates";
import AuctionDescription from "../components/auction-view/AuctionDescription";
import AuctionViewingDates from "../components/auction-view/AuctionViewingDates";
import AuctionBuyerPremiums from "../components/auction-view/AuctionBuyerPremiums";
import AuctionCatalogLink from "../components/auction-view/AuctionCatalogLink";
import AuctionDetails from "../components/auction-view/AuctionDetails.vue";
import PageComponent from "../components/core/PageComponent";

export default {
  name: "AuctionView",
  components: {
    PageComponent,
    AuctionCatalogLink,
    AuctionBuyerPremiums,
    AuctionViewingDates,
    AuctionDescription,
    auctionDates,
    LotList,
    AuctionDetails
},

  data() {
    return {
      auctionData: null,
    };
  },

  computed: {
    ...mapState(["auctionObject"]),
    ...mapGetters(["currentLocale", "is_authorized"]),
    auction() {
      return this.auctionObject.data;
    },
  },
  methods: {
    ...mapActions(["getAuctionData", "registerAsBidder"]),
  },
  beforeMount() {
    this.getAuctionData(this.$route.params.uuid);
  },
};
</script>

<style scoped lang="scss">
.ta-auction-view-loader {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
