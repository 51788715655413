<template>
  <div v-if="auction.viewing_start_date && auction.viewing_end_date">
    <slot></slot>
    <div class="ta-auction-view-dates mb-1">{{ auction.viewing_start_date | datetime }} -
      {{ auction.viewing_end_date | datetime }}
    </div>
    <count-down-timer :start_date="auction.viewing_start_date"
                      :end_date="auction.viewing_end_date"></count-down-timer>
  </div>
</template>

<script>
import CountDownTimer from "../CountDownTimer";

export default {
  name: "AuctionViewingDates",
  components: {CountDownTimer},

  props: {
    auction: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
