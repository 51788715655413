<template>
  <!-- If bidding is finished on the lot but it is unsold. No one made bid on it -->
  <div v-if="isLotUnsold" class="ta-lot-sold">
    <div class="alert alert-danger">
      <div>{{ $t('The item has not been sold') }}
      </div>
    </div>
  </div>

  <div v-else>
    <!-- If bidding is finished on the lot and lot is sold -->
    <div v-if="isLotSold" class="ta-lot-sold" :class="[iWonTheItem ? 'ta-lot-sold-by-me' : '']">
      <hr>
      <!-- If I am the buyer-->
      <div v-if="iWonTheItem" class="alert alert-success">
        <strong>{{ $t('Congratulations') }}.</strong>
        <div>{{ $t('You have bought this item for') }}
          <strong>{{ lot.winning_bid.amount | asCurrency(lot.currency.code) }}</strong>
        </div>
      </div>
      <!-- If I am NOT the buyer -->
      <div v-else class="alert alert-info">
        <div>{{ $t('The item was sold for') }} <strong>{{
            lot.winning_bid.amount | asCurrency(lot.currency.code)
          }}</strong>
        </div>
      </div>
    </div>

    <!-- If bidding continues on the lot -->
    <div v-else>
      <!-- If I am the latest bidder -->
      <div v-if="isCurrentUserLastBidder"
           class="alert alert-success py-2 px-3 d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" style="width: 20px" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <small class="mx-2">{{ $t('You are the highest bidder') }}</small>
        <a href="javascript:void(0)" @click="onShowMaxBid" class="btn-link text-sm">
          {{ $t('Increase max bid') }}
        </a>
      </div>
      <!-- If someone else is latest bidder but I made a bid also -->
      <div v-else-if="lot.has_my_bid && !isCurrentUserLastBidder"
           class="alert alert-danger py-2 px-3 d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" style="width: 16px" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
        </svg>
        <small class="mx-2">
          {{ $t('You have been outbidden') }}
        </small>
        <a href="javascript:void(0)" @click="onShowMaxBid" class="btn-link text-sm">
          {{ $t('Increase max bid') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LatestBidNotification",
  props: {
    isLotSold: Boolean,
    isLotUnsold: Boolean,
    iWonTheItem: Boolean,
    lot: Object,
    isCurrentUserLastBidder: Boolean
  },
  methods: {
    onShowMaxBid() {
      this.$emit('showMaxBid')
    }
  }
}
</script>

<style scoped>

</style>
