<template>
  <filter-group :title="$t('Departments')"
                v-if="!(model && model.length && mainConfig.hideDepartmentsIfSelected)"
                id="collapse-departments"
                class="ta-filter-group"
  >
    <div v-if="departments.loading" class="py-3 text-center">
      {{$t('Loading...')}}
    </div>
    <department-list v-else
      :departments="departments.data"
      v-model="model"
      @change="filtersChanged"
    />
  </filter-group>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import {updateRoute} from "../../../helpers";
import DepartmentList from "../../lot-filters/DepartmentList";
import FilterGroup from "../../lot-filters/FilterGroup";
export default {
  name: "FilterDepartmentList",
  components: {FilterGroup, DepartmentList},
  data: () => ({
    model: []
  }),
  computed: {
    ...mapState(['lotQueryParams', 'departments', 'mainConfig'])
  },
  watch: {
    lotQueryParams() {
      this.model = this.lotQueryParams.department
    },
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged(){
      this.setLotFilters({
        department: this.model,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      updateRoute();
    }
  },
  mounted() {
    this.model = this.lotQueryParams.department
  }
}
</script>

<style scoped>

</style>
