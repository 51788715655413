<template>
  <div>
    <hr>
    <div class="row">
      <div class="col">
        <a v-if="mainConfig.signupUrl" :href="mainConfig.signupUrl"
           @click="signupHandler({event: $event})"
           class="btn btn-primary btn-block justify-content-center d-flex align-items-center">
          <svg style="width: 20px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
          </svg>
          {{ $t('Sign up') }}
        </a>
        <router-link v-else :to="{'name': 'Signup'}"
           @click="signupHandler({event: $event})"
           class="btn btn-primary btn-block justify-content-center d-flex align-items-center">
          <svg style="width: 20px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
          </svg>
          {{ $t('Sign up') }}
        </router-link>
      </div>
      <div class="col">
        <a v-if="mainConfig.loginUrl" :href="mainConfig.loginUrl"
           @click="loginHandler({event: $event})"
           class="btn btn-secondary btn-block justify-content-center d-flex align-items-center">
          <svg style="width: 20px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"/>
          </svg>
          {{ $t('Log in') }}
        </a>
        <router-link v-else :to="{'name': 'Login'}"
           @click="loginHandler({event: $event})"
           class="btn btn-secondary btn-block justify-content-center d-flex align-items-center">
          <svg style="width: 20px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"/>
          </svg>
          {{ $t('Log in') }}
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "LoginSignupButtons",
  computed: {
    ...mapState(['mainConfig'])
  },
  methods: {
    ...mapActions(['loginHandler', 'signupHandler'])
  }
}
</script>

<style scoped>

</style>
