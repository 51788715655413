import Vue from 'vue'
import i18n from "../i18n";
import axiosClient from "../services/axios";
import logger from "../logger";
import CONFIG from '../user-config'
import {getVuexFilters, hideHTMLElements, showHTMLElements} from '../helpers';
import router from "../router";


export async function getAuctions({commit, state}, {pagination, filters = null}) {
  commit('setAuctionsLoading', true)
  commit('mutateAuctionPagination', pagination);
  commit('mutateAuctionFilters', filters);

  const {data, status} = await axiosClient.get('website/auctions/timed/', {
    params: {...pagination, ...filters}
  });
  // router.push({name: router.currentRoute.name, query: {...state.auctions.pagination, ...state.auctions.filters.urlParams}});
  commit('setAuctionsLoading', false)

  if (status === 200) {
    commit('setAuctions', data);
  } else {
    logger.error("Auctions were not fetched ", data);
  }
}

export async function getAuctionData({commit}, uuid) {
  commit('setAuctionObjectLoading', true);
  try {
    const {data, status} = await axiosClient.get(`website/auctions/timed/${uuid}`)
    if (status === 200) {
      commit('setAuctionObjectData', data);
      commit('setAuctionObjectLoading', false);
    } else {
      logger.error(`Auction data was not fetched for uuid: ${uuid}`, data);
    }
  } catch (e) {
    commit('setAuctionObjectLoading', false);
  }
}

export async function getLots({getters, state, commit, dispatch}) {
  commit('setLotsLoading', true);

  const params = getVuexFilters();

  let url = `website/lots/`;
  switch (router.app.$route.name) {
    case 'PrivateTreatyLots':
      url += `?private_treaty=true`;
      break;
    default:
  }
  const {data, status} = await axiosClient.get(url, {
    // params: {auction_uuid, ...pagination, ...filters}
    params
  });
  if (status === 200) {
    // If according to user config the pagination type is set to buttons, we clear previous data
    if (!state.lots.preserveOldLots) {
      commit('emptyLots');
    }
    commit('setPreserveOldLots', false);
    commit('setLots', data.lots);
    dispatch('getLotDataSocket', state.lots.results.filter(l => l.is_dynamic).map(lot => lot.uuid));
    commit('setLotsLoading', false);
  } else {
    logger.error(`Lot data was not fetched`, data);
  }
}

export async function getLotData({state, commit, dispatch}, uuid) {
  commit('setLotObjectLoading', true);
  commit('setAuctionObjectLoading', true);

  const {data, status} = await axiosClient.get(`website/lots/${uuid}`);
  if (status === 200) {
    commit('setLotObjectData', data);
    commit('setAuctionObjectData', data.auction);
    commit('setAuctionObjectLoading', false);
    commit('setLotObjectLoading', false);
    if (data.is_dynamic) {
      dispatch('getLotDataSocket', [uuid])
    }
  } else {
    logger.error(`Lot data was not fetched for uuid: ${uuid}`, data);
  }
}

export async function getDepartments({state, getters, commit}) {
  commit('setDepartmentsLoading', true);

  const params = {...getVuexFilters()};
  delete params.page;
  delete params.limit;
  delete params.sort;
  delete params['sort-by'];
  try {
    const {data, status} = await axiosClient.get(`website/departments`, {
      params
    })

    if (status === 200) {
      commit(`setDepartments`, data.departments);
      commit('setDepartmentsLoading', false);

      const selectedDept = getters.selectedDepartment;
      if (selectedDept) {
        commit('setCategories', selectedDept.categories);
        commit('setDynamicFields', selectedDept.dynamic_fields_template);
      }
    }
  } catch (e) {
    commit(`setDepartments`, []);
    commit('setDepartmentsLoading', false);
    console.log(e);
    logger.error(`Error while getting departments: ${e.response.data}`)
  }
}

export async function getGenericData(_, url) {
  const {data, status} = await axiosClient.get(url);
  if (status === 200) {
    return data;
  } else {
    logger.error(`Data was not fetched`, data);
  }
}

export async function toggleWatchlist({state, commit}, {entity_name, uuid, is_favored}) {
  let entity = null;
  let entityObject = null;

  let entity_meta = {};

  switch (entity_name) {
    case 'auction':
      entity = state.auctions.results.filter(e => e.uuid === uuid)[0];
      entity_meta.url = 'website/auctions/favorite/';
      entity_meta.payload = {
        auction_uuid: uuid
      };
      entity_meta.success_message = entity.is_favored ? i18n.t("Auction has been removed from your watch list") : i18n.t("Auction has been added into your watch list");
      break;
    case 'lot':
      entity = state.lots.results.filter(e => e.uuid === uuid)[0];
      entityObject = state.lotObject.data;
      entity_meta.url = 'website/lots/favorite/';
      entity_meta.payload = {
        lot_uuid: uuid
      };
      entity_meta.success_message = is_favored ? i18n.t("Lot has been removed from your watch list") : i18n.t("Lot has been added into your watch list");
      break;
    default:
      console.error('Invalid OTAR Entity!');
      return;
  }

  const request = !is_favored
    ? axiosClient.post(entity_meta.url, entity_meta.payload)
    : axiosClient.delete(entity_meta.url, {data: entity_meta.payload});

  request.then(() => {
    commit('showNotification', {
      title: i18n.t('Notification'),
      text: entity_meta.success_message,
      variant: 'success'
    });
    if (entity)
      entity.is_favored = !entity.is_favored;

    if (entityObject)
      entityObject.is_favored = !entityObject.is_favored;
  });
}

export async function jumpIntoLot({state, commit}, {auction_uuid, lot_no}) {
  try {
    const {data, status} = await axiosClient.get(`website/lots/${auction_uuid}/${lot_no}`);
    commit('setLotObjectLoading', false);
    if (status === 200) {
      commit('setLotObjectData', data);
    }
  } catch (e) {
    if (e.response.status !== 404) {
      logger.error(`Lot data was not fetched for auction uuid: ${auction_uuid} and lot_no: ${lot_no}`);
    }
  }
}

export function getLotDataSocket({commit, dispatch}, lot_uuids) {
  if (!lot_uuids.length) {
    return;
  }
  dispatch('socketLoginCallback')
    .then(() => {
      let extraFields = ['has_my_bid'];
      if (CONFIG.showLatestBids) {
        extraFields.push('last_bids')
      }
      Vue.prototype.$socket.sendObj({
        'action': 'get_lot_data',
        'data': {
          lot_uuids,
          extra_fields: extraFields
        }
      });
    });
}

export function socket__notification({state, commit}, context) {
  commit('fireSignal');
  commit('showModalMsgBox', {
    title: i18n.t('Invalid Bid!'),
    content: `${context.data.message}`
  });
}

export function socket__lot_updated({commit}, context) {
  commit('fireSignal')
  commit('mutateLotObject', {
    data: Object.fromEntries(Object.entries(context.data).filter(([key]) => !key.includes('fetch'))),
    fetch: context.data.fetch
  });
  commit('setLotObjectLoading', false);
}

export function socket__bidder_updated({state, commit}, context) {
  // TODO This needs to be revisited
  return;
  /*if (state.customer.uuid !== context.data.customer_uuid)
    return;

  commit('mutateBidderStatus', context.data);

  let toastParams = {
    title: i18n.t('Notification'),
    autoHideDelay: 250000,
  };
  switch (context.data.current_bidder_status) {
    case "approved":
      toastParams.text = i18n.t("You have been approved as a bidder, congratulations.");
      toastParams.variant = "success";
      break;
    case "rejected":
      toastParams.text = i18n.t("You have been rejected as a bidder.");
      toastParams.variant = "danger";
      break;
    case "pending":
      toastParams.text = i18n.t("You are being considered as a bidder, please stand by.");
      toastParams.variant = "warning";
      break;
  }

  commit('showNotification', toastParams);*/
}

export async function getCustomerMeta({commit}) {
  const {data, status} = await axiosClient.get('website/auth/me');
  if (status === 200) {
    commit('mutateCustomerMeta', data);
  }
}

export async function registerAsBidder({state, dispatch}, auction) {
  if (auction.is_bidder) {
    return;
  }
  dispatch('socketLoginCallback')
    .then(() => {
      if (!state.is_authorized) {
        return;
      }

      axiosClient.post('website/bidders/create/', {
        "auction_uuid": auction.uuid
      }).then(() => {
        state.auctionObject.data.is_bidder = true;
      }).catch(() => {
        console.error('Failed to register as bidder');
      });
    })
}

export async function createMaxBid({commit}, payload) {
  const {status} = await axiosClient.post('website/lots/create-max-bid/', payload);
  if (status === 200) {
    commit('mutateLotMaxBid', payload.amount);

    commit('setHasMyBid')

    commit('showNotification', {
      title: i18n.t('Max Bid Created!'),
      text: i18n.t("You have created a Max Bid with amount of {amount}", {amount: payload.amount}),
      variant: 'success'
    });
  }
}

export function loginHandler({state}, {event}) {
  if (typeof state.mainConfig.loginHandler === 'function') {
    state.mainConfig.loginHandler(event);
  }
}

export function signupHandler({state}, {event}) {
  if (typeof state.mainConfig.signupHandler === 'function') {
    state.mainConfig.signupHandler(event);
  }
}

export function linkHandler({state}, {event, link}) {
  if (typeof link.handler === 'function') {
    link.handler(event)
  }
}

export async function show401Notification({state, commit}) {
  commit('showModalMsgBox', {
    title: i18n.t('Unauthorized!'),
    content: `${i18n.t("You must be authorized to perform this action,")} <a href=${state.login_url}>${i18n.t('click here to login.')}</a>`
  });
}

export async function sendBidInSocket({state, commit, dispatch}, payload) {
  if (state.is_authorized) {
    Vue.prototype.$socket.sendObj(payload);
    commit('setHasMyBid')
  } else {
    dispatch('show401Notification')
  }
}

export async function socketOpen({state}) {
  return new Promise((resolve, reject) => {
    checkSocket();
    let timeout;

    function checkSocket() {
      if (state.socket.isOpened) {
        clearTimeout(timeout)
        resolve();
      } else {
        timeout = setTimeout(() => {
          checkSocket()
        }, 200)
      }
    }
  })
}

export async function sendSocketLogin({state, dispatch}) {
  await dispatch('socketOpen');
  const token = Vue.cookie.get(CONFIG.artisioCookieName);
  // if (!token) {
  //   state.socket.loginFinished = true;
  //   return;
  // }
  Vue.prototype.$socket.sendObj({
    'action': 'login',
    'data': {
      token,
      artisio_client_id: CONFIG.clientId
    },
  });
}

export async function sendSocketLogout(_) {
  Vue.prototype.$socket.sendObj({
    'action': 'logout',
    'data': {},
  });
}

export async function socket__login_callback({state}, {data}) {
  if (data.customer) {
    state.is_authorized = true;
    state.customer = data.customer;
  }
  state.socket.loginFinished = true;
}

export async function socket__logout_callback(_) {

}

export async function socketLoginCallback({state}) {
  return new Promise((resolve, reject) => {
    checkSocket();
    let timeout;

    function checkSocket() {
      if (state.socket.loginFinished) {
        clearTimeout(timeout)
        resolve();
      } else {
        timeout = setTimeout(() => {
          checkSocket()
        }, 200)
      }
    }
  })
}

export async function login({commit, dispatch}, payload) {
  return new Promise((resolve, reject) => {
    axiosClient.post('website/auth/login/', payload).then((responseData) => {
      resolve(responseData.data);
      showHTMLElements(CONFIG.visibleForAuthSelector);
      hideHTMLElements(CONFIG.visibleForGuestsSelector);

      responseData.data.remember = payload.remember
      commit('setCurrentUser', responseData.data);
      dispatch('sendSocketLogin');
    }).catch((err) => {
      reject(err.response);
    });
  })
}

export async function signup({commit}, {payload}) {
  return new Promise((resolve, reject) => {
    payload.addresses = [
      {
        'type': 'billing',
        'is_primary': true,
        ...payload.billing_address
      },
      {
        'type': 'shipping',
        'is_primary': true,
        ...payload.shipping_address
      }
    ];
    delete payload.billing_address;
    delete payload.shipping_address;

    if (Object.values(payload.bank_account).every(value => value === "")) {
      payload.bank_account = null;
    }
    axiosClient.post('website/auth/register/', payload).then((responseData) => {
      resolve(responseData.data.message);
    }).catch((err) => {
      reject(err.response.data.errors);
    });
  })
}

export async function profileUpdate({commit}, {payload}) {
  return new Promise((resolve, reject) => {
    payload.addresses = [
      {
        'type': 'billing',
        'is_primary': true,
        ...payload.billing_address
      },
      {
        'type': 'shipping',
        'is_primary': true,
        ...payload.shipping_address
      }
    ];
    delete payload.billing_address;
    delete payload.shipping_address;

    if (Object.values(payload.bank_account).every(value => value === "")) {
      payload.bank_account = null;
    }
    axiosClient.patch('website/auth/profile/', payload).then((responseData) => {
      resolve(responseData.data.message);
    }).catch((err) => {
      reject(err.response.data.errors);
    });
  })
}

export async function validatePasswordResetToken({commit}, {token}) {
  //TODO
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("The link is invalid or expired")
    }, 1000)
  })
}

export async function resetPassword({commit}, payload) {
  return new Promise((resolve, reject) => {
    axiosClient.post('website/auth/password-reset/', payload).then((responseData) => {
      resolve(responseData.data.message);
    }).catch((err) => {
      reject(err.response.data.message);
    });
  })
}

export async function requestPasswordReset(_, email) {
  return new Promise((resolve, reject) => {
    axiosClient.post('website/auth/request-password-reset/', {'email': email}).then((response) => {
      resolve(response.data.message);
    }).catch((err) => {
      reject(err.response.data.message);
    })
  })
}

export async function verifyEmailAddress(_, token) {
  return new Promise((resolve, reject) => {
    axiosClient.post('website/auth/email-verification/', {'token': token}).then((response) => {
      resolve(response.data.message);
    }).catch((err) => {
      reject(err.response.data['Error']);
    })
  });
}

export async function saveLotFilters({commit}, filters) {
  commit('setLotFilters', filters);
}

export async function addToFavourites({dispatch, state}) {
  if (!state.is_authorized) {
    dispatch('show401Notification');
    return;
  }
  const lot = state.lotObject.data;
  dispatch('toggleWatchlist', {
    entity_name: 'lot',
    uuid: lot.uuid,
    is_favored: lot.is_favored,
  })
}

export async function submitMakeAnOffer({commit}, {lot_uuid, amount}) {
  return await axiosClient.post('website/user-offer-commission', {lot_uuid, amount});
}

export async function getInvoices({commit}, {pagination, filters = null}) {
  commit('setInvoicesLoading', true)
  commit('mutateInvoicePagination', pagination);
  commit('mutateInvoiceFilters', filters);

  const {data, status} = await axiosClient.get('website/invoices/', {
    params: {...pagination, ...filters}
  });
  commit('setInvoicesLoading', false);

  if (status === 200) {
    commit('setInvoices', data);
  } else {
    logger.error("Invoices were not fetched ", data);
  }
}

export async function getInvoicePdf({commit}, {uuid}) {
  const {data, status} = await axiosClient.get(`website/invoices/${uuid}/pdf`);
  if (status === 200) {
    return data.base64;
  }
  logger.error(`Invoice pdf was not fetched for ${uuid}`, data);
  return false;
}

export async function requestPaymentUrl({commit}, {invoiceUuid, providerName}) {
  return new Promise((resolve, reject) => {
    axiosClient.get(`website/invoices/${invoiceUuid}/${providerName}/payment-link`).then(({data}) => {
      resolve(data.url);
    }).catch(() => {
      logger.error(`Invoice payment url was not fetched for invoice: ${invoiceUuid} and provider: ${providerName}`);
      reject();
    });
  });
}

export async function getSettlements({commit}, {pagination, filters = null}) {
  commit('setSettlementsLoading', true)
  commit('mutateSettlementPagination', pagination);
  commit('mutateSettlementFilters', filters);

  const {data, status} = await axiosClient.get('website/settlements/', {
    params: {...pagination, ...filters}
  });
  commit('setSettlementsLoading', false);

  if (status === 200) {
    commit('setSettlements', data);
  } else {
    logger.error("Settlements were not fetched ", data);
  }
}

export async function getSettlementPdf({commit}, {uuid}) {
  const {data, status} = await axiosClient.get(`website/settlements/${uuid}/pdf`);
  if (status === 200) {
    return data.base64;
  }
  logger.error(`Settlement pdf was not fetched for ${uuid}`, data);
  return false;
}


export async function getItems({commit}, {pagination, filters = null}) {
  commit('setItemsLoading', true)
  commit('mutateItemPagination', pagination);
  commit('mutateItemFilters', filters);

  const {data, status} = await axiosClient.get('website/items/', {
    params: {...pagination, ...filters}
  });
  commit('setItemsLoading', false);

  if (status === 200) {
    commit('setItems', data);
  } else {
    logger.error("Items were not fetched ", data);
  }
}


export async function getTemporaryItems({commit}, {pagination, filters = null}) {
  commit('setTemporaryItemsLoading', true)
  commit('mutateTemporaryItemPagination', pagination);
  commit('mutateTemporaryItemFilters', filters);

  const {data, status} = await axiosClient.get('website/items/temporary/', {
    params: {...pagination, ...filters}
  });
  commit('setTemporaryItemsLoading', false);

  if (status === 200) {
    commit('setTemporaryItems', data);
  } else {
    logger.error("Temporary Items were not fetched ", data);
  }
}


export async function getDepartmentsForSellAnItem({state, commit}, {force = false}) {
  if (state.departmentsForSellAnItem && !force) {
    return;
  }
  const {data, status} = await axiosClient.get('website/departments/all-with-categories/');

  if (status === 200) {
    commit('setDepartmentsForSellAnItem', data);
  } else {
    logger.error("Departments were not fetched ", data);
  }
}

export async function saveTemporaryItem({dispatch, commit, state}, payload) {
  try {
    const response = await axiosClient.post('website/items/temporary', payload);
    // if (response.status === 201) {
    //   dispatch('getTemporaryItems')
    // }
    return response
  } catch (e) {
    logger.error(e.message);
    return false;
  }
}

export async function getDepartmentsForHome({commit}) {
  commit('setHomeDepartmentsLoading', true);

  try {
    const {data, status} = await axiosClient.get(`website/departments`)

    if (status === 200) {
      commit(`setHomeDepartments`, data.departments);
      commit('setHomeDepartmentsLoading', false);
    }
  } catch (e) {
    commit(`setHomeDepartments`, []);
    commit('setHomeDepartmentsLoading', false);
    console.log(e);
    logger.error(`Error while getting departments: ${e.response.data}`)
  }
}
