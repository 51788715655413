<template>
  <div v-if="mainConfig.jumpToLot" class="ta-jump-to-lot">
    <b-input-group size="sm">
      <b-form-input v-model="lot_no" :placeholder="$t('Jump to lot')"></b-form-input>
      <b-input-group-append>
        <b-button @click="jumpToLot" variant="primary" :disabled="!lot_no"
                  class="ta-lot-bid-btn d-flex align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg"
               style="width: 20px;"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
          </svg>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "LotViewJumpToLot",
  data: () => ({
    lot_no: null,
  }),
  computed: {
    ...mapState(['mainConfig'])
  },
  methods: {
    ...mapActions(['jumpIntoLot']),
    async jumpToLot() {
      // TODO must be implemented
      /*await this.jumpIntoLot({auction_uuid: this.auctionUuid, lot_no: this.lot_no});

      if (this.lotObjectData.uuid) {
        this.$router.push({name: 'LotView', params: {uuid: this.lotObjectData.lot_no}});
      } else {
        this.showNotification({
          title: i18n.t('Warning'),
          text: i18n.t("Lot hasn't been found."),
          variant: 'warning'
        });
      }*/
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import.scss";

.ta-jump-to-lot {
  width: 200px;
  margin-left: 1rem;
}


@include media-breakpoint-down(xs) {
  .ta-jump-to-lot {
    width: 100%;
    margin: 0 0 1rem;
  }
}
</style>
