<template>
  <div class="position-fixed" style="left: 50%; transform: translate(-50%, 50%); z-index: 9999">
    <b-toast v-model="toast.visible" :title="toast.title" :variant="toast.variant" :href="toast.href" :auto-hide-delay="toast.autoHideDelay" solid static>
      {{ toast.text }}
    </b-toast>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AtaToast",

  computed: {
    ...mapState(['toast']),
  },
}
</script>

<style scoped>

</style>
