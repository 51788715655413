import {PAGING_TYPE_PAGINATION} from "./constants";

const TEMPLATES = {
  lotListItemTemplate: `
    {{image}}
    <div class="ta-lot-list-item-desc">
      <div class="ta-lotno-bids-info d-flex justify-content-between">
        {{lotNumber}}
        {{numberOfBids}}
      </div>
      {{title}}
      {{collectionInformation}}
      {{startPrice}}
      {{buyNowPrice}}
    </div>
    <div class="ta-lot-list-item-buttons">
      {{addToWatchlist}}
      {{countdownTimer}}
    </div>
  `,
  lotFiltersTemplate: `
    <lot-list-items-count />
    <filter-department-list />
    <filter-category-list />
    <filter-price-range />
    <filter-reserve-range />
    <filter-estimate-range />
    <filter-lot-range />
    <filter-with-image />
    <filter-dynamic-fields />
  `,
  lotListTemplate: `
    <lot-list-filters />
    <div class="ta-lot-section">
      <div class="ta-lot-list-header-top">
        <lot-keyword-search />
        <jump-to-lot />
      </div>
      <hr class="mt-0">
      <div class="ta-lot-list-header ">
        <lot-list-per-page-select />
        <div class="ta-lot-search-wrapper mb-3">
          <sorting-dropdown/>
          <lot-list-switch-view/>
          <lot-list-filter-mobile-button/>
        </div>
      </div>
      <lot-list-items />
      <div class="ta-lot-list-footer">
        <lot-list-pagination-summary/>
        <lot-list-pagination-buttons/>
      </div>
    </div>
  `,
  lotViewTemplate: `
    <lot-view-header>
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="ta-lot-breadcrumbs">
            <lot-view-breadcrumbs />
            <lot-view-jump-to-lot />
          </div>
        </div>
        <div class="col-md-4">
          <lot-view-prev-next-buttons />
        </div>
      </div>
    </lot-view-header>
    <div class="row">
      <div class="col-lg-8">
        <div class="ta-lot-title-wrapper">
          <lot-view-title/>
          <lot-view-lot-no/>
        </div>
        <lot-view-images/>
        <lot-view-social-share/>
      </div>
      <div class="col-lg-4">
        <lot-view-bidding/>
      </div>
      <div class="col-lg-8">
        <lot-view-description :lot="lot" />
      </div>
    </div>
  `
}

const DEFAULT_CONFIG = {
  /**
   * Your unique Client ID
   */
  clientId: null,

  /**
   * The language in which the UI should be displayed
   */
  lang: "en",

  /**
   * The default page that should be displayed when opening online auctions page
   * and bidding app starts working.
   *
   * Available options: '/auctions', '/lots'
   * You can set default page to be one specific auction or lot by specifying the following string
   * /lots/{LOT_UUID}
   * /auctions/{Auction_UUID}
   */
  defaultPage: "/home",

  /**
   * The URL on which user will be redirected when clicking on login buttons/links
   */
  loginUrl: null,

  /**
   * Javascript function which will be executed when login button is clicked.
   * You must provide either `loginUrl` or `loginHandler`
   * If you provide both both will work.
   */
  loginHandler: null,

  /**
   * The URL on which user will be redirected when clicking on signup buttons/links
   */
  signupUrl: null,

  /**
   * Javascript function which will be executed when signup button is clicked.
   * You must provide either `signupUrl` or `signupHandler`
   * If you provide both both will work.
   */
  signupHandler: null,

  /**
   * Links which will be displayed bellow bidding section.
   * (Ex: Terms and Conditions, Bidding Instructions, Shipping Details)
   * Texts specified must be in the configured language
   * Each link must have   -  `text` && (url || handler)`
   */
  links: [],

  /**
   * The URL which must return translations on all languages.
   * If you plan to have only English language you can skip this or set it to an empty string.
   * Response Example:
   * {
   *   "en": {
   *     "Buy now": "Buy now",
   *     "Place a Bid": "Place a Bid"
   *   },
   *   "de": {
   *     "Buy now": "Kaufe jetzt",
   *     "Place a Bid": "Ein Gebot abgeben"
   *   }
   * }
   */
  translationsUrl: null,

  /**
   * Translations object
   *
   * Example:
   * {
   *   "en": {
   *     "Buy now": "Buy now",
   *     "Place a Bid": "Place a Bid"
   *   },
   *   "de": {
   *     "Buy now": "Kaufe jetzt",
   *     "Place a Bid": "Ein Gebot abgeben"
   *   }
   * }
   */
  translations: {},

  /**
   * NOT FULLY IMPLEMENTED
   * ---------------------
   * Which social share icons to be displayed.
   * This can be either boolean or array
   * boolean true   - All supported social sharing buttons will be displayed
   * boolean false  - None of the social sharing buttons will be displayed
   * array          - Only those buttons will be displayed
   * Available social sharing buttons: 'email', 'facebook', 'twitter', 'linkedin'
   *
   * Example: `socialShares: ['facebook', 'email', 'twitter']`
   */
  socialShares: false,

  /**
   * To show print button or not.
   * This can be either boolean or function
   * boolean true    - The button will be displayed and default printing behavior will happen
   * boolean false   - The button will be hidden
   * Function        - The button will be displayed and specified function will
   *                    be called
   */
  printButton: false,

  /**
   * NOT FULLY IMPLEMENTED
   * ---------------------
   * Whether to show "Jump to lot" input or not
   */
  jumpToLot: true,

  /**
   * NOT FULLY IMPLEMENTED
   * ---------------------
   * Whether to show "Previous", "Next" and "Back to list" buttons or not
   */
  showBreadcrumbs: true,

  /**
   * NOT FULLY IMPLEMENTED
   * ---------------------
   * Whether to show breadcrumbs or not.
   */
  showPrevNextList: true,

  /**
   * Boolean - To show latest 5 bids in lot view or not.
   */
  showLatestBids: false,

  /**
   * Whether to enable "Add to watchlist" button or not
   */
  enableWatchlist: true,

  /**
   * Whether to show select box for page size or not
   */
  perPageSelect: true,

  /**
   * Whether to show authentication buttons or not
   */
  enableAuth: true,

  /**
   * Whether to enable bidding or not
   */
  enableBidding: true,

  /**
   * Whether to enable sidebar filters or not
   */
  enableFilters: true,

  /**
   * Whether to enable sorting options dropdown
   */
  enableSorting: true,

  /**
   * Whether to show countdown timer or not
   */
  showCountdown: true,

  /**
   * Whether to show description or lot in sidebar or not.
   * If you set it to false it will be displayed bellow images
   */
  descriptionInSidebar: false,

  /**
   * Whether to move share and print buttons in sidebar
   * If you set it to false they will be displayed bellow images
   */
  sharesInSidebar: false,

  /**
   * CSS Selector.
   * These elements will be visible when user is guest, but hidden when user is authorized
   */
  visibleForGuestsSelector: '.ta-visible-for-guest',

  /**
   * CSS Selector.
   * These elements will be visible when user is authorized, but hidden when user is guest
   */
  visibleForAuthSelector: '.ta-visible-for-auth',

  /**
   * Artisio SSO cookie name
   */
  artisioCookieName: 'artisio_timed_sso',

  /**
   * Whether to enable private treaty buttons like: "Make an offer" and "Make an Enquiry"
   */
  enablePrivateTreaty: false,

  /**
   * Artisio branch uuid for which you want to display content on website.
   * If you leave this null it will display content for all branches
   */
  branchUuid: null,

  /**
   * Whether to show collection information in lot listing an in lot inner page
   */
  showCollectionInfo: true,

  /**
   * Dynamic fields which must be rendered for lot description
   * Giving false here means that item's actual description should be rendered
   * Giving true here means that all dynamic fields should be iterated and rendered
   * In array you should give Artisio dynamic field names which will be used to render fields
   *
   * @type Array|Boolean
   */
  descriptionDynamicFields: false,

  /**
   * Hide department list from sidebar if department is selected
   */
  hideDepartmentsIfSelected: false,

  templates: {
    ...TEMPLATES
  },

  /**
   * The type how the pagination should be handled
   * Options: 'PAGINATION', 'LOAD_MORE', 'SCROLL'
   */
  paginationType:  'PAGINATION', // 'PAGINATION', 'LOAD_MORE', 'SCROLL'
};
const USER_CONFIG = {};
const STRING_OPTIONS = [
  "clientId",
  "lang",
  "defaultPage",
  "loginUrl",
  "signupUrl",
  "translationsUrl",
  "visibleForGuestsSelector",
  "visibleForAuthSelector",
  "artisioCookieName",
  "branchUuid",
  "paginationType"
];
const BOOLEAN_OPTIONS = [
  "socialShares",
  "jumpToLot",
  "showBreadcrumbs",
  "showPrevNextList",
  "showLatestBids",
  "enableWatchlist",
  "perPageSelect",
  "enableAuth",
  "enableBidding",
  "enableFilters",
  "enableSorting",
  "showCountdown",
  "descriptionInSidebar",
  "sharesInSidebar",
  "enablePrivateTreaty",
  "showCollectionInfo",
  "hideDepartmentsIfSelected"
];
const el = document.querySelector("#artisioTimedAuctions");
let dataset = {};
if (!el) {
  console.error(`Artisio Webapp HTML Element with id "artisioTimedAuctions" does not exist`);
} else {
  dataset = {
    ...el.dataset
  };
}

// Prepare boolean variables
for (const field of BOOLEAN_OPTIONS) {
  if (dataset[field] !== undefined) {
    dataset[field] = ["true", "1"].includes(dataset[field].toLowerCase());
  }
}

// Merge properties taken from div data attributes with JavaScript
dataset = {
  ...dataset,
  ...window.artisioWebApp
}
for (let field of [...BOOLEAN_OPTIONS, ...STRING_OPTIONS]) {
  if (dataset[field] !== undefined) {
    USER_CONFIG[field] = dataset[field];
  }
}

if (dataset.links !== undefined) {
  try {
    USER_CONFIG.links = JSON.parse(dataset.links);
    USER_CONFIG.links.forEach(
      (link) => (link.handler = convertHandlerToFn(link.handler))
    );
  } catch (e) {
    console.error(`"links" is not valid JSON`);
  }
}
if (dataset.loginHandler) {
  USER_CONFIG.loginHandler = convertHandlerToFn(dataset.loginHandler);
}

if (dataset.signupHandler) {
  USER_CONFIG.signupHandler = convertHandlerToFn(dataset.signupHandler);
}
if (dataset.printButton !== undefined) {
  if (typeof dataset.printButton === 'boolean') {
    USER_CONFIG.printButton = dataset.printButton;
  } else {
    if (["true", "1"].includes(dataset.printButton.toLowerCase())) {
      USER_CONFIG.printButton = true;
    } else if (["false", "0"].includes(dataset.printButton.toLowerCase())) {
      USER_CONFIG.printButton = false;
    } else {
      USER_CONFIG.printButton = convertHandlerToFn(dataset.printButton);
    }
  }
}

if (dataset.descriptionDynamicFields !== undefined) {
  if (typeof dataset.descriptionDynamicFields === 'boolean') {
    USER_CONFIG.descriptionDynamicFields = dataset.descriptionDynamicFields;
  } else {
    if (typeof dataset.descriptionDynamicFields === 'string') {
      if (["true", "1"].includes(dataset.descriptionDynamicFields.toLowerCase())) {
        USER_CONFIG.descriptionDynamicFields = true;
      } else if (["false", "0"].includes(dataset.descriptionDynamicFields.toLowerCase())) {
        USER_CONFIG.descriptionDynamicFields = false;
      }
    } else {
      USER_CONFIG.descriptionDynamicFields = dataset.descriptionDynamicFields;
    }
  }
}

if (dataset.translations) {
  USER_CONFIG.translations = dataset.translations;
}

if (dataset.templates) {
  USER_CONFIG.templates = dataset.templates;
}
/**
 * Converts global handler string into function
 *
 * @param handlerStr
 * @returns {Function}
 */
function convertHandlerToFn(handlerStr) {
  if (typeof handlerStr === "function") {
    return handlerStr;
  }
  const parts = handlerStr.split(".");
  let fn = window;
  for (let part of parts) {
    fn = fn[part];
    if (!fn) {
      throw new Error(`"${handlerStr}" is not a valid function`);
    }
  }
  if (typeof fn !== "function") {
    throw new Error(`"${handlerStr}" is not a valid function`);
  }
  return fn;
}

let CONFIG = {};
mergeDeep(CONFIG, DEFAULT_CONFIG, USER_CONFIG)

if (!CONFIG.clientId) {
  throw new Error('Please provide "clientId"');
}

if (!(typeof CONFIG.translations === 'object')) {
  throw new Error(`"translations" config must be object`)
}

export default CONFIG;

export {DEFAULT_CONFIG, USER_CONFIG};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
