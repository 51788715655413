export const COOKIE_AUTH_KEY = 'OTA_COOKIE_AUTH_KEY' //TODO This might change
export const AUCTION_PAGINATION_PAGE = 1
export const AUCTION_PAGINATION_LIMIT = 5
export const LOT_LIST_DEFAULT_PAGE = 1
export const LOT_LIST_DEFAULT_LIMIT = 5
export const LOT_DEFAULT_SORT_FIELD = 'visited'
export const LOT_DEFAULT_SORT_BY = 'desc'
export const INVOICE_PAGINATION_PAGE = 1
export const INVOICE_PAGINATION_LIMIT = 5
export const SETTLEMENT_PAGINATION_PAGE = 1
export const SETTLEMENT_PAGINATION_LIMIT = 5
export const ITEM_PAGINATION_PAGE = 1
export const ITEM_PAGINATION_LIMIT = 5
export const TEMPORARY_ITEM_PAGINATION_PAGE = 1
export const TEMPORARY_ITEM_PAGINATION_LIMIT = 5

export const PAGING_TYPE_PAGINATION = 'PAGINATION';
export const PAGING_TYPE_LOAD_MORE = 'LOAD_MORE';
export const PAGING_TYPE_SCROLL = 'SCROLL';

export const TEMPLATE_KEYWORD_COMPONENT_MAP = {
  filterDepartmentList: '<filter-department-list />',
  filterCategoryList: '<filter-category-list />',
  filterPriceRange: '<filter-price-range />',
  filterReserveRange: '<filter-reserve-range />',
  filterEstimateRange: '<filter-estimate-range />',
  filterLotNoRange: '<filter-lot-range />',
  filterWithImage: '<filter-with-image />',
  filterDynamicFields: '<filter-dynamic-fields />',
  filters: '<lot-list-filters />',
  keywordSearch: '<lot-keyword-search/>',
  jumpToLot: '<jump-to-lot />',
  perPageSelect: '<lot-list-per-page-select />',
  sortingDropdown: '<sorting-dropdown />',
  switchViewButton: '<lot-list-switch-view />',
  filtersMobileButton: '<lot-list-filter-mobile-button />',
  items: '<lot-list-items />',
  paginationSummary: '<lot-list-pagination-summary/>',
  paginationButtons: '<lot-list-pagination-buttons/>',
  itemsCount: '<lot-list-items-count />',

}
