<template>
  <b-link v-if="auction.catalog && auction.catalog.url" :href="auction.catalog.url" target="_blank">
    <b-icon-collection/>
    {{ $t('View Auction Catalog') }}
  </b-link>
</template>

<script>
export default {
  name: "AuctionCatalogLink",

  props: {
    auction: {
      type: Object,
      required: true
    },
  }
}
</script>

<style scoped>

</style>
