<template>
  <b-popover :show="show"
             :target="target"
             triggers="click focus"
             placement="top"
             custom-class="ta-popover-confirmation"
             container="artisioTimedAuctions">
    <template v-slot:title>
      {{ $t('Please Confirm') }}
      <button class="btn btn-sm btn-link ta-btn-close" @click="hide">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </template>

    <div>
      <p class="ta-confirmation-message">
        {{ $t('Are you sure you want to place a bid') }}?
      </p>

      <div class="alert alert-info py-1 px-2 rounded-0">
        <div class="font-weight-bold">{{ $t('The following buyer premiums will apply') }}</div>
        <div>
          <div v-for="(premium, ind) of lot.auction.premium_terms" :key="ind" class="d-flex justify-content-between">
            <div>{{ premium.percent }}% {{$t('premium')}}</div>
            <div>{{ $t('Over') }} {{ premium.amount_over | asCurrency(lot.currency.code) }}</div>
          </div>
        </div>
      </div>

      <p>
        <b-form-checkbox v-model="checked" value="1" unchecked-value="0">
          {{ $t("Understood! Don't ask me this again on this item") }}
        </b-form-checkbox>
      </p>
    </div>
    <div class="d-flex justify-content-center align-items-center p-2">
      <button class="btn btn-sm btn-primary" @click="confirm">
        {{ $t('Yes') }}
      </button>
      <button class="btn btn-sm btn-danger ml-2" @click="hide">
        {{ $t('No') }}
      </button>
    </div>
  </b-popover>
</template>

<script>
export default {
  name: "BidConfirmation",
  props: {
    show: Boolean,
    target: String,
    lot: Object
  },
  emits: [],
  data() {
    return {
      checked: false,
    }
  },
  methods: {
    confirm() {
      this.hide();
      this.$emit('confirm', this.checked);
    },
    hide() {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../bootstrap/import";

#artisioTimedAuctions {
  .ta-popover-confirmation {
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    max-width: 420px;

    /deep/ .popover-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ta-confirmation-message {
      font-weight: 500;
      font-size: 1.125rem;
    }

    .ta-btn-close {
      position: absolute;
      right: 1rem;
      padding: 0;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray-100;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
      svg {
        width: 16px;
      }
    }
  }
}
</style>
