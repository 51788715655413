import Vue from "vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import {messages} from 'vee-validate/dist/locale/en.json';
import i18n from "../../i18n";

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule]
  });
});

extend('at_least_lowercase_alpha', {
  validate: (value) => {
    if (!/[a-z]/.test(value)) {
      return false;
    }
    return true;
  },
  message: i18n.t('{_field_} must contain at least 1 lowercase letter')
})

extend('at_least_uppercase_alpha', {
  validate: (value) => {
    if (!/[A-Z]/.test(value)) {
      return false;
    }
    return true;
  },
  message: i18n.t('{_field_} must contain at least 1 uppercase letter')
})

extend('at_least_digit', {
  validate: (value) => {
    if (!/[0-9]/.test(value)) {
      return false;
    }
    return true;
  },
  message: i18n.t('{_field_} must contain at least 1 digit')
})

extend('at_least_special', {
  validate: (value) => {
    if (!/[`~!@#$%^&*()\-=_+\[\]\{\};:'"\\\|<>,.\/\?]/.test(value)) {
      return false;
    }
    return true;
  },
  message: i18n.t('{_field_} must contain at least 1 special character')
})
// extend("required", {
//   ...required,
//   message: i18n.t('{_field_} field is required')
// });

// Add a rule.
extend('password', {
  validate: value => {

    if (value.length < 8) {
      return false;
    }
    if (!/[a-z]/.test(value)) {
      return false;
    }
    if (!/[A-Z]/.test(value)) {
      return false;
    }
    if (!/[0-9]/.test(value)) {
      return false;
    }
    if (!/[`~!@#$%^&*()-=_+\[\]\{\};:'"\\\|<>,.\/\?]/.test(value)) {
      return false;
    }
    
    return true;
  },
  message: 'Your password does not satisfy requirements'
});


Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
