<template>
  <button class="ta-filter-menu-btn d-lg-none" @click="filtersMenuClick">
    <svg style="width: 24px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
    </svg>
  </button>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "LotListFilterMobileButton",
  methods: {
    ...mapMutations(['setFiltersMenuOpenMobile']),
    filtersMenuClick() {
      this.setFiltersMenuOpenMobile(true);
    },
  }
}
</script>

<style scoped>

</style>
