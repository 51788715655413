<template>
  <lot-filter v-if="mainConfig.enableFilters"
              :open="filtersMenuOpenMobile"
              @menuClose="setFiltersMenuOpenMobile(false)"/>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import LotFilter from "../../lot-list/LotFilter";

export default {
  name: "LotListFilters",
  components: {LotFilter},
  computed: {
    ...mapState(['mainConfig', 'filtersMenuOpenMobile'])
  },
  methods: {
    ...mapMutations(['setFiltersMenuOpenMobile'])
  }
}
</script>

<style scoped>

</style>
