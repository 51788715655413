<template>
  <filter-group :title="$t('Price Range')" id="collapse-for-price">
    <div class="row">
      <div class="col">
        <b-form-input
          type="number"
          v-model="price_from"
          v-debounce:1000ms="filtersChanged"
          :placeholder="$t('Low')"
        ></b-form-input>
      </div>
      <div class="col">
        <b-form-input
          type="number"
          v-model="price_to"
          v-debounce:1000ms="filtersChanged"
          :placeholder="$t('High')"
        ></b-form-input>
      </div>
    </div>
  </filter-group>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import {updateRoute} from "../../../helpers";
import FilterGroup from "../../lot-filters/FilterGroup";

export default {
  name: "FilterPriceRange",
  components: {FilterGroup},
  data: () => ({
    price_from: null,
    price_to: null,
  }),
  computed: {
    ...mapState(['lotQueryParams']),
  },
  watch: {
    lotQueryParams() {
      this.price_from = this.lotQueryParams.price_from
      this.price_to = this.lotQueryParams.price_to
    },
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged() {
      this.setLotFilters({
        price_from: this.price_from,
        price_to: this.price_to,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      updateRoute();
    }
  },
  mounted() {
    this.price_from = this.lotQueryParams.price_from
    this.price_to = this.lotQueryParams.price_to
  }
}
</script>

<style scoped>

</style>
