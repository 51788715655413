<template>
  <div v-if="mainConfig.showBreadcrumbs" class="flex-grow-1">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb text-small text-nowrap">
        <li class="breadcrumb-item"><a href="#">Commercial Vehicles</a></li>
        <li class="breadcrumb-item"><a href="#">Van for sale</a></li>
        <li class="breadcrumb-item"><a href="#">Citroen</a></li>
        <li class="breadcrumb-item active" aria-current="page">Berlingo</li>
      </ol>
    </nav>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotViewBreadcrumbs",
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style lang="scss" scoped>
  #artisioTimedAuctions {
    .breadcrumb {
      margin-bottom: 0;
      background-color: transparent;
      padding: 0.5rem 0;
    }
  }
</style>
