<template>
  <div class="ta-filters-criteria">
    <div
      v-b-toggle="id"
      class="ta-filters-criteria-title d-flex justify-content-between"
    >
      <span>{{ title }}</span>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style="width: 20px"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </span>
    </div>
    <b-collapse visible :id="id" class="ta-filters-criteria-body">
      <slot></slot>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "FilterGroup",
  props: {
    title: String,
    id: String,
  },
};
</script>

<style lang="scss" scoped>
@import "../../bootstrap/import";

.ta-filters-criteria {
  padding: 0.75rem 0;
  border-bottom: 1px solid $gray-200;
}

.ta-filters-criteria-title {
  font-size: 16px;
  font-weight: 500;
}

.ta-filters-criteria-body {
  padding: 0.5rem 0;
}
</style>
