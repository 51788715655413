<template>
  <div id="add-to-watchlist" v-b-tooltip.hover class="d-flex align-items-center justify-content-center">
    <button
      @click="addToFavourites()"
      class="btn btn-link ta-btn-watchlist">
      <b-icon v-if="!lot.is_favored" icon="heart"></b-icon>
      <b-icon v-else icon="heart-fill"></b-icon>
    </button>
    <b-tooltip v-if="!is_authorized" container="artisioTimedAuctions" target="add-to-watchlist">
      {{ $t('Please login to proceed') }}
    </b-tooltip>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "WatchlistButton",
  props: ['lot'],
  computed: {
    ...mapState(['is_authorized'])
  },
  methods: {
    ...mapActions(['addToFavourites'])
  }
}
</script>

<style scoped>

</style>
