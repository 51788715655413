<template>
  <lot-list></lot-list>
</template>

<script>
  import LotList from "../components/LotList";
  export default {
    name: "Lots",
    components: {LotList}
  }
</script>

<style scoped>

</style>
