<template>
  <div v-if="mainConfig.showPrevNextList" class="ta-lot-next-previous d-flex justify-content-between align-items-center text-sm">
      <a href="#" class="d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg"
             class="w-4 mr-2"
             fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
        {{ $t('Previous') }}
      </a>

      <a href="#">
        {{ $t('Back to List') }}
      </a>

      <a href="#" class="d-flex align-items-center">
        {{ $t('Next') }}
        <svg xmlns="http://www.w3.org/2000/svg"
             class="w-4 ml-2"
             fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
        </svg>
      </a>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotViewPrevNextButtons",
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style lang="scss" scoped>
</style>
