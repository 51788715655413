<template>
  <div class="ta-page-auctions">
    <h1>{{ $t('Auction Calendar ') }}</h1>
    <div class="d-flex justify-content-start">
      <!--      <auction-filters :pagination="{ 'page': page, 'limit': limit, }" :auction-filter-details="auctionFilterDetails"-->
      <!--                       @update-query="updateQuery"></auction-filters>-->
      <auction-filters @change="filtersChanged"></auction-filters>
      <div class="ta-auction-list">
        <h4>{{ auctions.totalRows }} {{ $t('auctions') }}</h4>

        <div class="ta-auction-list-header ">
          <div class="ta-per-page-wrapper">
            {{ $t('Show') }}
            <b-form-select class="mx-2" v-model="limit" @change="updatePagination"
                           :options="perPageOptions"></b-form-select>
            {{ $t('entries') }}
          </div>
          <div class="ta-auction-search-wrapper d-flex align-items-center">
            {{ $t('Search') }}:
            <b-form-input :placeholder="$t('Search for auctions')" class="ml-2" v-model="filters.q"
                          v-debounce:300ms="updateRoute"></b-form-input>
          </div>
        </div>
        <div v-if="auctions.loading" class="ta-auction-loader">
          <b-spinner label="Spinning"></b-spinner>
          <span class="mt-2">{{ $t('Loading...') }}</span>
        </div>
        <template v-if="!auctions.loading">
          <div class="ta-auction-list-wrapper">
            <auction-list-item v-for="auction in auctions.results" :key="auction.id" :auction="auction">
            </auction-list-item>
          </div>
          <div class="ta-auction-list-footer">
            <div class="ta-auction-pagination-summary">
              {{ $t('Showing {start} to {finish} of {total} entries',
                { start: (auctions.pagination.page - 1) * auctions.pagination.limit + 1,
                  finish: (auctions.pagination.page * auctions.pagination.limit > auctions.totalRows) ? auctions.totalRows : auctions.pagination.page * auctions.pagination.limit,
                  total: auctions.totalRows
                })
              }}
            </div>
            <b-pagination
                v-model="page"
                :total-rows="auctions.totalRows"
                :per-page="auctions.pagination.limit"
                @input="updatePagination"
                class="ta-auction-list-pagination"
            ></b-pagination>
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex'
import AuctionListItem from "../components/AuctionListItem";
import AuctionFilters from "../components/AuctionFilters";
import {AUCTION_PAGINATION_PAGE, AUCTION_PAGINATION_LIMIT} from '../constants';
import logger from "../logger";

export default {
  name: 'Auctions',
  components: {
    AuctionFilters,
    AuctionListItem

  },
  data: () => {
    return {
      limit: AUCTION_PAGINATION_LIMIT,
      page: AUCTION_PAGINATION_PAGE,
      filters: {
        q: null
      },
      delayedRequest: null,
      query: null,
      normalizedQuery: null,
    }
  },

  computed: {
    ...mapState(['perPageOptions', 'auctions']),
    ...mapGetters(['currentLocale']),
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        // make actions with newVal.page
        if (newVal.page)
          this.page = newVal.page;
        if (newVal.limit)
          this.limit = newVal.limit;

        for (const key in this.filters) {
          if(newVal[key])
            this.filters[key] = newVal[key];
        }

        this.getUpdatedAuctions();
      }
    }
  },

  methods: {
    ...mapActions(['getAuctions', 'setAuctionPagination']),

    filtersChanged(filters) {
      this.filters = filters;
      this.updateRoute();
    },

    updatePagination() {
      this.updateRoute()
    },

    updateRoute() {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: this.page,
          limit: this.limit,
          ...this.filters
        }
      }).catch(err => {
        logger.error('Failed to update route for auctions', err);
      })
    },

    getUpdatedAuctions() {
      this.getAuctions({
        pagination: {page: this.page, limit: this.limit},
        filters: this.filters
      });
    }

  },
}
</script>

<style scoped lang="scss">
.ta-auction-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ta-auction-loader {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ta-per-page-wrapper {
  display: flex;
  align-items: center;
}

.ta-auction-list {
  flex: 1;
}

.ta-auction-list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination.ta-auction-list-pagination {
  margin-bottom: 0 !important;
}
</style>
