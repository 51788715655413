<template>
  <a :href="`mailto:?Subject=${$options.filters.currentLocale(lot.title)}`" class="btn btn-primary btn-block">
    {{ $t('Make an Enquiry') }}
  </a>
</template>

<script>
export default {
  name: "MakeAnEnquiryButton",
  props: {
    lot: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
