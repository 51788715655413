<script>
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
import Carousel from "../components/Carousel";
import GallerySlideshow from '../plugins/vue-gallery-slideshow/src/component/GallerySlideshow';
import LotViewBidding from "../components/configuration/lot-view/LotViewBidding";
import LotInfo from "../components/LotInfo";
import SocialShareIcons from "../components/lot-view/SocialShareIcons";
import LotViewSkeleton from "../components/lot-view/LotViewSkeleton"
import PageComponent from "../components/core/PageComponent";
import logger from "../logger";
import {TEMPLATE_KEYWORD_COMPONENT_MAP} from "../constants";
import Vue from "vue";

export default {
  name: "LotView",

  components: {
    PageComponent,
    LotViewSkeleton,
    SocialShareIcons,
    LotInfo,
    LotViewBidding,
    Carousel,
    GallerySlideshow

  },
  data: () => ({
    templateRender: null
  }),
  computed: {
    ...mapState(['lotObject', 'auctionObject', 'mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data
    }),
    ...mapGetters(['is_authorized', 'currentLocale', 'fallbackLocale', 'dynamicFields']),
    lotDynamicFields() {
      return this.lot.dynamic_fields[this.currentLocale] || this.lot.dynamic_fields[this.fallbackLocale]
    },
  },
  watch: {
    'mainConfig.templates.lotViewTemplate': {
      immediate: true,
      handler() {
        let TEMPLATE = `
          <page-component class="ta-lot-view">
            <div v-if="lotObject.loading || !auctionObject.data.increments">
              <lot-view-skeleton/>
            </div>
            <div v-else>
              ${this.mainConfig.templates.lotViewTemplate}
            </div>
          </page-component>
          </div>
        `;
        for (let key in TEMPLATE_KEYWORD_COMPONENT_MAP) {
          TEMPLATE = TEMPLATE.replace(`{{${key}}}`, TEMPLATE_KEYWORD_COMPONENT_MAP[key])
        }
        var res = Vue.compile(TEMPLATE);

        this.templateRender = res.render;

        // staticRenderFns belong into $options,
        // appearantly
        this.$options.staticRenderFns = []

        // clean the cache of static elements
        // this is a cache with the results from the staticRenderFns
        this._staticTrees = []

        // Fill it with the new staticRenderFns
        for (var i in res.staticRenderFns) {
          //staticRenderFns.push(res.staticRenderFns[i]);
          this.$options.staticRenderFns.push(res.staticRenderFns[i])
        }
      }
    }
  },
  methods: {
    ...mapActions(['getLotData', 'getAuctionData', 'getCustomerMeta', 'registerAsBidder']),
    ...mapMutations(['setLotObjectData']),
  },

  async beforeMount() {

    try {
      await this.getLotData(this.$route.params.lotno);
    } catch (e) {
      logger.error(e)
    }

    // await this.getAuctionData(this.lot.auction_uuid);

    const auction = this.auctionObject.data;
    await this.registerAsBidder(auction);
  },
  beforeDestroy() {
    this.setLotObjectData({})
  },
  render(h, context) {
    if (!this.templateRender) {
      return h('div', 'Loading...')
    }
    return this.templateRender();
  }
}
</script>

<style scoped lang="scss">
@import "../bootstrap/import";

#artisioTimedAuctions {
  .ta-lot-breadcrumbs-wrapper {
    border-bottom: 2px solid $gray-200;
    margin-bottom: 1rem;
  }

  .ta-lot-breadcrumbs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ta-jump-to-lot {
    width: 9rem;
  }

  .ta-lot-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ta-lot-title {
    font-size: 1.5rem;
    min-height: 40px;
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    .ta-lot-title-wrapper {
      flex-direction: column;
      align-items: start;
    }
    .ta-lot-title {
      font-size: 1.25rem;
    }
    .ta-lot-next-previous {
      border-top: 2px solid $gray-200;
      //margin-top: 1rem;
    }
    .ta-lot-next-previous {
      margin-bottom: 1rem;
    }

  }

  @include media-breakpoint-down(xs) {
    .ta-lot-breadcrumbs {
      flex-direction: column;
      align-items: start;
    }
    .ta-jump-to-lot {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

</style>
