<template>
  <count-down-timer v-if="mainConfig.showCountdown && lot.start_date && lot.end_date"
                    :start_date="lot.start_date"
                    :end_date="lot.end_date"
                    :label="label"/>
</template>

<script>
import {mapState} from "vuex";
import CountDownTimer from "../../CountDownTimer";

export default {
  name: "LotCollectionInformation",
  components: {CountDownTimer},
  props: {
    lot: {
      required: true,
      type: Object
    },
    label: {
      type: [Boolean],
      default: true
    }
  },
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style scoped>

</style>
