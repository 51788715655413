import CONFIG from "../user-config";
import {
  AUCTION_PAGINATION_LIMIT,
  AUCTION_PAGINATION_PAGE,
  INVOICE_PAGINATION_LIMIT,
  INVOICE_PAGINATION_PAGE,
  ITEM_PAGINATION_LIMIT,
  ITEM_PAGINATION_PAGE,
  LOT_DEFAULT_SORT_BY,
  LOT_DEFAULT_SORT_FIELD,
  LOT_LIST_DEFAULT_LIMIT,
  LOT_LIST_DEFAULT_PAGE,
  SETTLEMENT_PAGINATION_LIMIT,
  SETTLEMENT_PAGINATION_PAGE,
  TEMPORARY_ITEM_PAGINATION_LIMIT,
  TEMPORARY_ITEM_PAGINATION_PAGE
} from "../constants";

let doNotShowConfirmOnBid = sessionStorage.getItem('ARTISIO_DO_NOT_SHOW_CONFIRM_ON_BID') || null;
if (doNotShowConfirmOnBid) {
  doNotShowConfirmOnBid = JSON.parse(doNotShowConfirmOnBid)
}

export default {
  // Configuration attributes added to root element of vue project
  mainConfig: CONFIG,
  timeDiff: null,
  is_authorized: false,
  signal: 0,
  customer: {},
  user: {
    token: null,
  },
  modalMsgBox: {
    visible: false,
    title: null,
    content: null,
    headerBgVariant: null,
    headerTextVariant: null,
  },
  toast: {
    visible: false,
    duration: 0,
    title: null,
    text: null,
    variant: null,
  },
  perPageOptions: [
    5, 10, 25, 50, 100,
  ],
  auctions: {
    loading: false,
    pagination: {
      page: AUCTION_PAGINATION_PAGE,
      limit: AUCTION_PAGINATION_LIMIT,
    },
    totalRows: 5,
    next: null,
    previous: null,
    results: [],
    categories: [],
    countries: [],
    departments: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  auctionObject: {
    loading: false,
    data: {},
  },
  lots: {
    loading: false,
    pagination: {
      page: LOT_LIST_DEFAULT_PAGE,
      limit: LOT_LIST_DEFAULT_LIMIT,
    },
    totalRows: null,
    results: [],
    preserveOldLots: false,
    categories: [],
    departments: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  lotObject: {
    loading: false,
    data: {}
  },
  departments: {
    loading: false,
    data: []
  },
  categories: {
    loading: false,
    data: []
  },
  dynamicFields: {
    loading: false,
    data: []
  },
  lotQueryParams: {
    q: '',
    department: [],
    category: [],
    reserve_low: '',
    reserve_high: '',
    estimate_low: '',
    estimate_high: '',
    withImages: false,
    lot_no_from: '',
    lot_no_to: '',
    page: LOT_LIST_DEFAULT_PAGE,
    limit: LOT_LIST_DEFAULT_LIMIT,
    sort: LOT_DEFAULT_SORT_FIELD,
    'sort-by': LOT_DEFAULT_SORT_BY
  },
  lotListViewType: localStorage.getItem('ARTISIO_LOT_LIST_VIEW_TYPE') || 'grid',
  doNotShowConfirmOnBid: doNotShowConfirmOnBid || {},
  socket: {
    isOpened: false,
    loginFinished: false
  },
  invoices: {
    loading: false,
    pagination: {
      page: INVOICE_PAGINATION_PAGE,
      limit: INVOICE_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  settlements: {
    loading: false,
    pagination: {
      page: SETTLEMENT_PAGINATION_PAGE,
      limit: SETTLEMENT_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  items: {
    loading: false,
    pagination: {
      page: ITEM_PAGINATION_PAGE,
      limit: ITEM_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  temporaryItems: {
    loading: false,
    pagination: {
      page: TEMPORARY_ITEM_PAGINATION_PAGE,
      limit: TEMPORARY_ITEM_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  sellAnItemModal: {
    show: false
  },
  departmentsForSellAnItem: null,
  categoriesMap: {},
  dynamicFieldsMap: {},
  error: {
    code: null,
    message: null
  },
  filtersMenuOpenMobile: false,
  homePage: {
    departments: {
      loading: false,
      data: []
    }
  }
}
