<template>
  <div>
    {{(lot.last_bid_amount || lot.start_price) | asCurrency(lot.currency.code)}}
  </div>
</template>

<script>
export default {
  name: "LotActualPrice",
  props: {
    lot: {
      required: true,
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
