<template>
  <div>
    <div v-if="toast.visible" class="alert" :class="`alert-${toast.variant}`">
      <h5>{{toast.title}}</h5>
      {{toast.text}}
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ToastNotification",
  computed: {
    ...mapState(['toast'])
  }
}
</script>

<style scoped>

</style>
