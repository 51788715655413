<template>
  <div class="ta-filters" :class="{ 'ta-filters-open': open }">
    <div class="ta-filters-header">
      <button
        class="ta-filter-menu-btn d-lg-none"
        @click="$emit('menuClose')"
        style="margin-left: -0.75rem"
      >
        <svg
          style="width: 28px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <h3 class="ta-filters-title m-0 mb-4">{{ $t("Filters") }}</h3>
      <button
        v-if="filtersApplied"
        class="btn btn-sm btn-link rounded-pill d-flex align-items-center"
        @click="clearFilters"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style="width: 18px"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        {{ $t("Reset") }}
      </button>
    </div>
    <div class="ta-lot-filter-list">
      <lot-filter-list />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "../../constants";
import {updateRoute} from "../../helpers";
import LotFilterList from "../lot-filters/LotFilterList";

export default {
  name: "LotFilter",
  components: {
    LotFilterList,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dynamic_fields: {}
    };
  },

  computed: {
    ...mapState(["lotQueryParams", "departments", "categories", "mainConfig"]),
    ...mapGetters(["currentLocale", "lotFilterDetails", "selectedDepartment", "filterableDynamicFields"]),

    filtersApplied() {
      for (const key in this.lotQueryParams) {
        if (key === "page" || key === "limit" || key === 'sort' || key === 'sort-by') {
          continue;
        }
        // If it is an empty array
        if (Array.isArray(this.lotQueryParams[key]) && !this.lotQueryParams[key].length) {
          continue;
        }
        if (
          (Array.isArray(this.lotQueryParams[key]) && this.lotQueryParams[key].length) ||
          this.lotQueryParams[key]
        ) {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    lotQueryParams() {
      console.log("Updated in vuex");
      this.updateFiltersFromVuex();
    },
  },

  methods: {
    ...mapActions(["getLots"]),
    ...mapMutations(["setLotFilters", "setDepartments", "setCategories", "setDynamicFields"]),

    filtersChanged() {
      this.setLotFilters({
        page: LOT_LIST_DEFAULT_PAGE,
        dynamicFields: this.dynamic_fields
      });
      updateRoute();
    },

    clearFilters() {
      Object.keys(this.lotQueryParams).forEach((field) => {
         if (field === "page" || field === "limit" || field === 'sort' || field === 'sort-by') {
          return;
        }
        if (Array.isArray(this.lotQueryParams[field])) {
          this.lotQueryParams[field] = [];
        } else {
          this.lotQueryParams[field] = null;
        }
      });

      Object.keys(this.dynamic_fields).forEach((field) => {
        if (Array.isArray(this.dynamic_fields[field])) {
          this.dynamic_fields[field] = [];
        } else {
          this.dynamic_fields[field] = null;
        }
      });

      this.filtersChanged();
      this.setDepartments([])
      this.setCategories([])
      this.setDynamicFields([])
    },
    updateFiltersFromVuex() {
      const query = this.lotQueryParams;

      this.dynamic_fields = {};
      for (let key in query) {
        // If query parameter starts with `dynamic_fields`
        if (key.indexOf('dynamic_fields-') === 0) {
          this.dynamic_fields[key.replace('dynamic_fields-', '')] = query[key];
        }
      }
      this.dynamic_fields = {...this.dynamic_fields};
    },
  },
  mounted() {
    this.updateFiltersFromVuex();
  },
};
</script>

<style lang="scss" scoped>
@import "../../bootstrap/import";

.ta-filters {
  margin-right: 1.5rem;
  width: 280px;
  min-width: 280px;
  overflow-x: hidden;
  overflow-y: auto;

  .ta-filters-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    padding: 1rem;
    right: -320px;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 320px;
    margin: 0;
    background-color: $white;
    z-index: 10;
    border-left: 1px solid $gray-400;
    transition: all 0.2s ease-in-out;
    .ta-filters-header {
      text-align: right;
    }

    &.ta-filters-open {
      right: 0;
      box-shadow: -50px 0 50px 20px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
