<template>
  <b-popover :show="show"
             :target="target"
             triggers="click focus"
             placement="top"
             custom-class="ta-popover-confirmation"
             container="artisioTimedAuctions">
    <template v-slot:title>
      {{ $t('Please Confirm') }}
      <button class="btn btn-sm btn-link ta-btn-close" @click="hide">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </template>
    <p class="mb-0">
      {{ $t('Are you sure you want to buy this item') }}?
    </p>

    <div class="d-flex justify-content-center align-items-center p-2">
      <button class="btn btn-sm btn-primary" @click="confirm(true)">
        {{ $t('Yes') }}
      </button>
      <button class="btn btn-sm btn-danger ml-2" @click="hide">
        {{ $t('No') }}
      </button>
    </div>
  </b-popover>
</template>

<script>
export default {
  name: "BuyNowConfirmation",
  props: {
    show: Boolean,
    target: String,
  },
  emits: [],
  data() {
    return {
      checked: false,
    }
  },
  methods: {
    confirm() {
      this.hide();
      this.$emit('confirm', this.checked);
    },
    hide() {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style scoped>

</style>
