<template>
  <div v-if="mainConfig.enableSorting" class="d-flex align-items-center flex-nowrap">
    <label class="mr-2 mb-0">{{ $t('Sort') }}: </label>
    <b-form-select
      :options="sortOptions"
      v-model="sort"
      @change="updateSorting"
    ></b-form-select>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {updateRoute} from "../../../helpers";

export default {
  name: "SortingDropdown",
  data: () => ({
    sort: 'end_date=desc',
  }),
  computed: {
    ...mapState(['lotQueryParams', 'mainConfig']),
    ...mapGetters(['dynamicFields']),
    sortOptions() {
      return [
        {text: this.$t('Most Visited/Viewed'), value: 'visited=desc'},
        {text: this.$t('Newest (Published)'), value: 'publish_date=desc'},
        {text: this.$t('Lowest Price'), value: 'price=asc'},
        {text: this.$t('Highest Price'), value: 'price=desc'},
        {text: this.$t('Highest Number of Bids'), value: 'bid_count=desc'},
        {text: this.$t('Least time Left'), value: 'end_date=asc'},
        ...this.dynamicFields.filter(f => f.sortable).map(f => ({
          text: `${f.label} ASC`,
          value: `dynamic_fields-${f.key}=asc`
        })),
        ...this.dynamicFields.filter(f => f.sortable).map(f => ({
          text: `${f.label} DESC`,
          value: `dynamic_fields-${f.key}=desc`
        }))
      ].sort((a, b) => a.text < b.text ? -1 : 1)
    }
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    updateSorting() {
      let [sort, sortBy] = this.sort.split('=')
      this.setLotFilters({
        sort,
        'sort-by': sortBy
      });
      updateRoute();
    },
  },

  mounted() {
    const sort = this.lotQueryParams.sort;
    const sortBy = this.lotQueryParams['sort-by'];
    if (sort && sortBy) {
      this.sort = `${sort}=${sortBy}`;
    }
  }
}
</script>

<style scoped>

</style>
