<template>
  <div class="ta-lot-list-item-thumb">
    <router-link :to="lotInnerPageRoute">
      <img
        :src="thumbnailSrc"
        @error="
            $event.target.src =
              'https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg'
          "
      />
    </router-link>
    <span v-if="lot.winning_bid" class="ta-lot-last-bid">
          {{ lot.winning_bid.amount | asCurrency(lot.currency.code) }}
        </span>
  </div>
</template>

<script>
export default {
  name: "LotListItemImage",
  props: {
    lot: {
      required: true,
      type: Object
    },
    lotInnerPageRoute: [String, Object]
  },
  computed: {
    thumbnailSrc() {
      return this.lot.images.length ? (this.lot.images[0].sm || this.lot.images[0].original).url : "";
    },
  }
}
</script>

<style scoped>

</style>
