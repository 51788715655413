<template>
  <div class="ta-per-page-wrapper mb-3">
    {{ $t('Show') }}
    <b-form-select class="mx-2"
                   v-model="limit"
                   @change="updatePagination"
                   :options="perPageOptions"></b-form-select>
    {{ $t('entries') }}
  </div>
</template>

<script>
import {updateRoute} from "../../../helpers";
import {mapMutations, mapState} from "vuex";
import {LOT_LIST_DEFAULT_LIMIT} from "../../../constants";

export default {
  name: "LotListPerPageSelect",
  data: () => ({
    limit: LOT_LIST_DEFAULT_LIMIT
  }),
  computed: {
    ...mapState(['perPageOptions', 'lotQueryParams'])
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    updatePagination() {
      this.setLotFilters({limit: this.limit});
      updateRoute();
    },
  },
  mounted() {
    this.limit = this.lotQueryParams.limit;
  }
}
</script>

<style scoped>

</style>
