<template>
  <b-form-group class="ta-auction-category-filter">
<!--    <h6 class="d-flex justify-content-between">-->
<!--      {{ title }}-->
<!--      <a v-if="selected.length" href="javascript:void(0)" @click="clearValue([])">{{ $t('Reset') }}</a>-->
<!--    </h6>-->
    <div class="ta-input-list-group-item">
      <b-form-checkbox
        v-for="option in options"
        v-model="selected"
        :key="option.value"
        :value="option.value"
        name="flavour-3a"
        @change="changeValue"
      >
        {{ option.text }} <small>({{option.count}} {{$t('lots')}})</small>
      </b-form-checkbox>
    </div>
    <!--    <div v-for="option in options"-->
    <!--         :key="option.value"-->
    <!--         @click="changeValue(option.value)"-->
    <!--         class="ta-input-list-group-item">-->
    <!--      {{ option.text }}-->
    <!--      <b-badge v-if="option.count !== undefined" variant="primary" pill>{{ option.count }}</b-badge>-->
    <!--    </div>-->
  </b-form-group>
</template>

<script>
export default {
  name: "InputListGroup",
  props: {
    title: String,
    options: Array,
    value: [String, Array]
  },
  data() {
    return {
      selected: []
    }
  },
  watch: {
    value(newValue) {
      this.selected = newValue || [];
    }
  },
  methods: {
    changeValue() {
      this.$emit('input', this.selected)
      this.$emit('change', this.selected)
    },
    clearValue() {
      this.selected = [];
      this.$emit('input', this.selected)
      this.$emit('change', this.selected)
    }
  },
  mounted() {
    if (typeof this.value === 'string') {
      this.selected = [this.value];
    } else {
      this.selected = this.value || [];
    }
  }
}
</script>

<style lang="scss" scoped>
.ta-input-list-group-item {
  cursor: pointer;
  justify-content: space-between;
  /deep/ label {
    cursor: pointer;
  }
}
</style>
