import Vue from "vue";

export function asCurrency (amount, currency) {
  return Intl.NumberFormat("en-GB", {
    style: "currency",
    currency,
    maximumSignificantDigits: 20,
  }).format(amount);
}

Vue.filter('asCurrency', asCurrency)
