<template>
  <div v-if="this.auction.premium_terms && this.auction.premium_terms.length > 0">
    <div class="row">
      <div class="col-sm-4">
        <b-table hover small :items="buyerPremiums"></b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuctionBuyerPremiums",

  props: {
    auction: {
      type: Object,
      required: true
    },
  },

  computed: {
    buyerPremiums() {
      const currencySymbol = this.auction.currency.symbol;

      this.auction.premium_terms.forEach(premium => {
        delete premium.uuid
        premium.amount_over = `${currencySymbol}${premium.amount_over}`;
        premium.percent = `${premium.percent}%`;
      });

      return this.auction.premium_terms;
    }
  }
}
</script>

<style scoped>

</style>
