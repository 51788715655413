<template>
  <div class="ta-auth-card ta-signup-form">
    <div class="text-center">
      <h3 class="font-weight-bold mt-3">{{ $t("Create an Account") }}</h3>
      <p>
        {{ $t("or") }}
        <router-link :to="{ name: 'Login' }">
          {{ $t("Login with existing account") }}
        </router-link>
      </p>
    </div>

    <FormAlert :success-message="successMessage" :error-message="errorMessage"/>

    <ValidationObserver ref="signupValidationObserver" v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <fieldset class="mb-4">
          <legend>
            {{ $t("Account Details") }}
          </legend>
          <div class="row">
            <div class="col-12 mb-3">
              <InputWidget
                v-model="model.email"
                :error="errors.email"
                id="input-email"
                type="email"
                :label="$t('Email Address')"
                rules="required|email"
                placeholder="name@example.com"
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.password"
                :error="errors.password"
                id="input-password"
                type="password"
                :show-password-toggle="true"
                :label="$t('Password')"
                rules="required|min:8|at_least_lowercase_alpha|at_least_uppercase_alpha|at_least_digit|at_least_special"
                auto-complete="off"
              >
                <template #append>
                  <b-input-group-text id="input-password-append">
              <span class="d-inline-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="width: 1.25rem"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
                  </b-input-group-text>
                </template>
              </InputWidget>
              <b-popover
                target="input-password-append"
                triggers="hover"
                placement="bottomleft"
                container="artisioTimedAuctions"
              >
                <template #title>
                  {{ $t("Password Policy") }}
                </template>
                <div>
                  <p class="font-weight-bold">
                    {{
                      $t("Your password must be at least {x} characters long and contain", { x: 8 })
                    }}
                  </p>
                  <ul class="pl-4">
                    <li class="">
                      {{ $t("At least one digit") }}
                    </li>
                    <li class="">
                      {{ $t("At least one lowercase letter") }}
                    </li>
                    <li class="">
                      {{ $t("At least one uppercase letter") }}
                    </li>
                    <li class="">
                      {{ $t("At least one special character") }}
                    </li>
                  </ul>
                </div>
              </b-popover>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.password_confirmation"
                :error="errors.password_confirmation"
                id="input-password-confirmation"
                type="password"
                :label="$t('Password Confirmation')"
                rules="required"
                auto-complete="off"
              >
              </InputWidget>
            </div>
          </div>
        </fieldset>
        <fieldset class="mb-4">
          <legend>
            {{ $t("Profile information") }}
          </legend>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-4 mb-3">
              <InputWidget
                v-model="model.first_name"
                :error="errors.first_name"
                id="input-first_name"
                type="text"
                :label="$t('First Name')"
                rules="required"
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-3">
              <InputWidget
                v-model="model.middle_name"
                :error="errors.middle_name"
                id="input-middle_name"
                type="text"
                :label="$t('Middle Name')"
                rules=""
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mb-3">
              <InputWidget
                v-model="model.last_name"
                :error="errors.last_name"
                id="input-last_name"
                type="text"
                :label="$t('Last Name')"
                rules="required"
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.title"
                :error="errors.title"
                id="input-title"
                type="select"
                :label="$t('Title')"
                rules="required"
                :options="titles"
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.personal_id"
                :error="errors.personal_id"
                id="input-personal_id"
                type="text"
                :label="$t('Personal ID')"
                rules=""
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.company_name"
                :error="errors.company_name"
                id="input-company_name"
                type="text"
                :label="$t('Company Name')"
                rules=""
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.phone"
                :error="errors.phone"
                id="input-phone"
                type="text"
                :label="$t('Phone')"
                rules="required|min:10"
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.is_company"
                id="input-is_company"
                type="checkbox"
                :checkbox_checked_value="true"
                :label="$t('Is company')"
                rules=""
              >
              </InputWidget>
            </div>
          </div>
        </fieldset>
        <fieldset class="mb-4">
          <legend>
            {{ $t("Billing Address") }}
          </legend>
          <div class="row">
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.billing_address.address_1"
                :error="errors.addresses[0][0][0].address_1"
                id="input-billing_address_address_1"
                type="text"
                :label="$t('Address 1')"
                rules="required"
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.billing_address.address_2"
                :error="errors.addresses[0][0][0].address_2"
                id="input-billing_address_address_2"
                type="text"
                :label="$t('Address 2')"
                rules=""
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.billing_address.country_code"
                :error="errors.addresses[0][0][0].country_code"
                id="input-billing_address_country"
                type="select"
                :label="$t('Country')"
                rules="required"
                :options="countries"
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.billing_address.state"
                :error="errors.addresses[0][0][0].state"
                id="input-billing_address_state"
                type="text"
                :label="$t('State')"
                rules=""
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.billing_address.city"
                :error="errors.addresses[0][0][0].city"
                id="input-billing_address_city"
                type="text"
                :label="$t('City')"
                rules="required"
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.billing_address.zip_code"
                :error="errors.addresses[0][0][0].zip_code"
                id="input-billing_address_zip_code"
                type="text"
                :label="$t('Zip Code')"
                rules="required"
              >
              </InputWidget>
            </div>
          </div>
        </fieldset>

        <fieldset class="mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <legend>
              {{ $t("Shipping Address") }}
            </legend>
            <b-form-checkbox
              class="mb-2 text-nowrap"
              id="sameAsBilling"
              v-model="sameAsBilling"
              value="accepted"
              unchecked-value="not_accepted"
            >
              {{ $t("Same as Billing Address") }}
            </b-form-checkbox>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.shipping_address.address_1"
                :error="errors.addresses[0][1][0].address_1"
                id="input-shipping_address_address_1"
                type="text"
                :label="$t('Address 1')"
                rules="required"
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.shipping_address.address_2"
                :error="errors.addresses[0][1][0].address_2"
                id="input-shipping_address_address_2"
                type="text"
                :label="$t('Address 2')"
                rules=""
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.shipping_address.country_code"
                :error="errors.addresses[0][1][0].country_code"
                id="input-shipping_address_country"
                type="select"
                :label="$t('Country')"
                rules="required"
                :options="countries"
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.shipping_address.state"
                :error="errors.addresses[0][1][0].state"
                id="input-shipping_address_state"
                type="text"
                :label="$t('State')"
                rules=""
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.shipping_address.city"
                :error="errors.addresses[0][1][0].city"
                id="input-shipping_address_city"
                type="text"
                :label="$t('City')"
                rules="required"
              >
              </InputWidget>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <InputWidget
                v-model="model.shipping_address.zip_code"
                :error="errors.addresses[0][1][0].zip_code"
                id="input-shipping_address_zip_code"
                type="text"
                :label="$t('Zip Code')"
                rules="required"
              >
              </InputWidget>
            </div>
          </div>
        </fieldset>

        <fieldset class="mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <legend>
              {{ $t("Bank Account") }}
              <b-icon-trash
                v-if="bankAccountFilled"
                @click="eraseBankAccount"
                style="font-size: 1.3rem; color: red"
              ></b-icon-trash>
            </legend>
          </div>

          <div class="row">
            <div class="col-md-4">
              <InputWidget
                v-model="model.bank_account.account_name"
                :error="errors.bank_account.account_name"
                id="input-bank_account_account_name"
                type="text"
                :label="$t('Account Name/Beneficiary')"
                :rules="`${bankAccountFilled ? 'required' : ''}`"
              >
              </InputWidget>
            </div>
            <div class="col-md-4">
              <InputWidget
                v-model="model.bank_account.sort_code"
                :error="errors.bank_account.sort_code"
                id="input-bank_account_sort_code"
                type="text"
                :label="$t('Sort code')"
                :rules="`${bankAccountFilled ? 'required' : ''}`"
              >
              </InputWidget>
            </div>
            <div class="col-md-4">
              <InputWidget
                v-model="model.bank_account.account_number"
                :error="errors.bank_account.account_number"
                id="input-bank_account_account_number"
                type="text"
                :label="$t('Account Number')"
                :rules="`${bankAccountFilled ? 'required' : ''}`"
              >
              </InputWidget>
            </div>
            <div class="col-md-4">
              <InputWidget
                v-model="model.bank_account.iban"
                :error="errors.bank_account.iban"
                id="input-bank_account_iban"
                type="text"
                :label="$t('IBAN')"
                rules=""
              >
              </InputWidget>
            </div>
            <div class="col-md-4">
              <InputWidget
                v-model="model.bank_account.swift"
                :error="errors.bank_account.swift"
                id="input-bank_account_swift"
                type="text"
                :label="$t('Swift')"
                rules=""
              >
              </InputWidget>
            </div>
            <div class="col-md-4">
              <InputWidget
                v-model="model.bank_account.bank_name"
                :error="errors.bank_account.bank_name"
                id="input-bank_account_bank_name"
                type="text"
                :label="$t('Bank Name')"
                :rules="`${bankAccountFilled ? 'required' : ''}`"
              >
              </InputWidget>
            </div>
            <div class="col-md-4">
              <InputWidget
                v-model="model.bank_account.address"
                :error="errors.bank_account.address"
                id="input-bank_account_address_1"
                type="text"
                :label="$t('Address 1')"
                rules=""
              >
              </InputWidget>
            </div>
            <div class="col-md-4">
              <InputWidget
                v-model="model.bank_account.address_2"
                :error="errors.bank_account.address_2"
                id="input-bank_account_address_2"
                type="text"
                :label="$t('Address 2')"
                rules=""
              >
              </InputWidget>
            </div>
            <div class="col-md-4">
              <InputWidget
                v-model="model.bank_account.address_3"
                :error="errors.bank_account.address_3"
                id="input-bank_account_address_3"
                type="text"
                :label="$t('Address 3')"
                rules=""
              >
              </InputWidget>
            </div>
          </div>
        </fieldset>

        <LoadingButton :loading="loading" class="mr-3 ta-btn-submit" :disabled="invalid">
          {{ $t("Signup") }}
        </LoadingButton>

        <b-button type="reset" @click="scrollToTop" variant="secondary">
          {{ $t("Reset") }}
        </b-button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import CONFIG from "../../user-config";
import LoadingButton from "../../components/core/LoadingButton.vue";
import InputWidget from "../../components/core/InputWidget";
import {getGenericData} from "../../store/actions";
import FormAlert from "../../components/core/FormAlert";
import {deepClone} from "../../helpers";

export default {
  name: "Signup",
  data() {
    return {
      loading: false,
      sameAsBilling: false,
      countries: null,
      titles: null,
      errorMessage: null,
      successMessage: null,
      bankAccountFilled: false,
      passwordUpdateFilled: false,
      model: {
        first_name: "",
        middle_name: "",
        last_name: "",
        title: "",
        email: "",
        company_name: "",
        is_company: "",
        personal_id: "",
        phone: "",
        password: "",
        password_confirmation: "",
        shipping_address: {
          address_1: "",
          address_2: "",
          country_code: "",
          state: "",
          city: "",
          zip_code: "",
        },
        billing_address: {
          address_1: "",
          address_2: "",
          country_code: "",
          state: "",
          city: "",
          zip_code: "",
        },
        bank_account: {
          account_name: "",
          sort_code: "",
          account_number: "",
          iban: "",
          swift: "",
          bank_name: "",
          address: "",
          address_2: "",
          address_3: "",
        }
      },
      errors: {
        first_name: null,
        middle_name: null,
        last_name: null,
        title: null,
        email: null,
        company_name: null,
        personal_id: null,
        phone: null,
        password: null,
        password_confirmation: null,
        addresses: [
          [
            [{
              address_1: null,
              address_2: null,
              country_code: null,
              state: null,
              city: null,
              zip_code: null,
            }],
            [{
              address_1: null,
              address_2: null,
              country_code: null,
              state: null,
              city: null,
              zip_code: null,
            }]
          ],
        ],
        bank_account: {
          account_name: null,
          sort_code: null,
          account_number: null,
          iban: null,
          swift: null,
          bank_name: null,
          address: null,
          address_2: null,
          address_3: null,
        }
      },
    };
  },
  watch: {
    sameAsBilling(newValue) {
      switch (newValue) {
        case "accepted":
          this.model.shipping_address = Object.assign({}, this.model.shipping_address, {...this.model.billing_address});
          break;
        default:
          Object.keys(this.model.shipping_address).forEach(key => this.model.shipping_address[key] = "");
          break;
      }
    },
    'model.bank_account': {
      deep: true,
      handler(newValue) {
        this.bankAccountFilled = Object.keys(newValue).filter(key => newValue[key] !== '').length > 0
      }
    },
  },
  computed: {
    ...mapGetters(["currentLocale"]),
    ...mapState(["customer"]),
  },
  methods: {
    ...mapActions(["signup", "getGenericData", "getCustomerMeta"]),

    eraseBankAccount() {
      Object.keys(this.model.bank_account).forEach(key => { this.model.bank_account[key] = "" });
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    constructPayload() {
      let payload = deepClone(this.model);
      if (!this.bankAccountFilled) {
        payload.bank_account = {};
      }
      payload.is_company = payload.is_company !== "";

      return payload;
    },

    onSubmit() {
      this.loading = true;
      this.signup({'payload': this.constructPayload()})
        .then((message) => {
          this.loading = false;
          this.$router.push({name: 'Login', params: {successMessage: message}});
        })
        .catch((errors) => {
          this.loading = false;
          this.successMessage = null;
          Object.keys(errors).forEach(key => this.errors[key] = errors[key]);

          this.scrollToTop();
        });
    },
  },
  components: {FormAlert, InputWidget, LoadingButton},
  async beforeMount() {
    this.countries = (await this.getGenericData('website/countries')).map(x => {
      const text = x.name | this.currentLocale
      return {'value': x.code, 'text': text ? text : x.name.en} //Fallback to "en"
    });
    this.countries.unshift({'text': 'Select Country...', 'value': null});

    this.titles = (await this.getGenericData('website/titles')).titles.map(title => {
      return {'value': title, 'text': title[0].toUpperCase() + title.slice(1)}
    });
    this.titles.unshift({'text': 'Select title...', 'value': null});
  }
};
</script>

<style lang="scss" scoped>
@import "../../bootstrap/import.scss";

#artisioTimedAuctions {
  .ta-signup-form {
    width: 800px;
    padding: 2rem;
  }

  .ta-signup-form {
    button {
      width: 140px;
    }
  }
}
</style>
