<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Vue from "vue";
import {TEMPLATE_KEYWORD_COMPONENT_MAP} from '../constants'

export default {
  name: "LotList",
  components: {},
  props: {
    auctionUuid: {
      type: String,
      default: null
    }
  },
  data: () => ({
    templateRender: null,
  }),
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        console.log("Route changed ", this.auctionUuid);
        this.setLotFilters({
          ...this.$route.query,
          auction_uuid: this.auctionUuid
        });
        this.getLots();
        this.getDepartments();
      },
    },
    'mainConfig.templates.lotListTemplate': {
      immediate: true,
      handler() {
        let TEMPLATE = `
          <div class="ta-lot-view" >
              ${this.mainConfig.templates.lotListTemplate}
          </div>
        `;
        for (let key in TEMPLATE_KEYWORD_COMPONENT_MAP) {
          TEMPLATE = TEMPLATE.replace(`{{${key}}}`, TEMPLATE_KEYWORD_COMPONENT_MAP[key])
        }
        var res = Vue.compile(TEMPLATE);

        this.templateRender = res.render;

        // staticRenderFns belong into $options,
        // appearantly
        this.$options.staticRenderFns = []

        // clean the cache of static elements
        // this is a cache with the results from the staticRenderFns
        this._staticTrees = []

        // Fill it with the new staticRenderFns
        for (var i in res.staticRenderFns) {
          //staticRenderFns.push(res.staticRenderFns[i]);
          this.$options.staticRenderFns.push(res.staticRenderFns[i])
        }
      }
    }
  },
  computed: {
    ...mapState(['lotListViewType', 'mainConfig']),
  },
  methods: {
    ...mapActions(['getLots', 'getDepartments']),
    ...mapMutations(['setLotFilters']),
  },
  render(h, context) {
    if (!this.templateRender) {
      return h('div', 'Loading...')
    }
    return this.templateRender();
  }
}
</script>

<style scoped lang="scss">
@import "../bootstrap/import.scss";

.ta-lot-view {
  display: flex;
}

.ta-lot-section {
  flex: 1;
}


.ta-lot-search-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.ta-lot-list-header-top {
  display: flex;
}

.ta-lot-list-header,
.ta-lot-list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-per-page-wrapper {
  display: flex;
  align-items: center;
}

.ta-lot-list-footer {
  margin-top: 1rem;
}

.btn-switch-view {
  outline: none;

  &:hover,
  &:focus {
    box-shadow: none;
    border: 1px solid gray
  }
}

@include media-breakpoint-down(xs) {
  .ta-lot-list {
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .ta-lot-list-header-top,
  .ta-lot-list-header {
    flex-direction: column;
  }
}

</style>
