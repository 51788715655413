<template>
  <div class="ta-auction-list-item">
    <div class="ta-auction-list-item-thumb">
      <router-link :to="{name: 'AuctionViewCatalog', params: {uuid: auction.uuid}}">
        <img :src="imageSrc" @error="$event.target.src='https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg'">
      </router-link>
    </div>
    <div class="ta-auction-list-item-desc">
      <router-link class="ta-auction-list-item-title" :to="{name: 'AuctionViewCatalog', params: {uuid: auction.uuid}}"><b>{{ auction.title | currentLocale }}</b></router-link>
      <div class="ta-auction-list-item-dates">
        {{auction.start_date | datetime }} - {{auction.end_date | datetime }}
      </div>
      <div class="ta-auction-list-item-lots">
        <i>{{auction.number_of_lots}} {{ $t('Lots') }}</i>
      </div>
<!--      <div class="ta-auction-list-item-category">-->
<!--        Jewellery-->
<!--      </div>-->
<!--      <div class="ta-auction-list-item-location">-->
<!--        London-->
<!--      </div>-->
    </div>
    <div class="ta-auction-list-item-actions">
      <b-button variant="outline-primary" @click="toggleWatchlist({ entity_name: 'auction', uuid: auction.uuid, is_favored: auction.is_favored })">
        <template v-if="!auction.is_favored">
          <b-icon icon="heart"></b-icon> {{$t('Add to watchlist')}}
        </template>
        <template v-else>
          <b-icon icon="heart-fill"></b-icon> {{$t('Remove from watchlist')}}
        </template>
      </b-button>
      <b-button @click="redirect" variant="primary">
        <b-icon icon="hammer"></b-icon> {{redirectBtnTxt}}
      </b-button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from "vuex";
export default {

  name: "AuctionListItem",
  props: {
    auction: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['is_authorized']),
    imageSrc() {
      return this.auction.image_url ?? 'https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg';
    },

    redirectBtnTxt() {
      return this.is_authorized ? this.$t('View Lots') : this.$t('Login to bid');
    }
  },

  methods: {
    ...mapActions(['toggleWatchlist']),

    redirect() {
      if(!this.is_authorized) {
        window.location.replace(this.mainConfig.loginUrl);
      } else {
        this.$router.push({
          name: 'AuctionView',
          params: {uuid: this.auction.uuid}
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
#artisioTimedAuctions {
  .ta-auction-list-item {
    display: flex;
    align-items: stretch;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #e2e2e2;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  .ta-auction-list-item-thumb {
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e5e5e5;

    img {
      max-width: 100%;
    }
  }

  .ta-auction-list-item-desc {
    padding: 15px;
    flex: 1;
  }

  .ta-auction-list-item-actions {
    padding: 15px;
    display: flex;
    flex-direction: column;

    > .btn {
      margin-bottom: 0.5rem;
    }
  }

  .ta-auction-list-item-title {
    font-size: 110%;
  }

  .ta-auction-list-item-dates {
    font-weight: 600;
  }
}
</style>
